<template>
  <div
    class="block"
    :class="['block-' + (type || 'custom'), 'block-' + (title || 'no-title'), fullscreen && 'fullscreen']"
  >
    <h2 v-if="title && (!/^block_/.test(title) || t[title])">
      <div>
        {{
          title.split(',').length > 1
            ? title
                .split(',')
                .map(d => t[d] || d)
                .join(' ')
                .capitalize(true)
            : t[title] || title
        }}
      </div>
      <slot name="title"></slot>
      <button class="icon" style="display: none" :class="{ dark: !fullscreen }" @click="fullscreen = !fullscreen">
        <svg-icon class="nx-fullscreen" name="nx-fullscreen"></svg-icon>
      </button>
    </h2>
    <div style="display: none" class="hack-title" v-else>
      {{ (title || type || 'block').replace('pdf', '').replace($root.params.screen, '').titleize() }}
    </div>
    <slot
      name="legend"
      v-if="(/plot-/.test(type) || 'bar' === type) && data && data.v() && data.v()[0] && data.v()[0].keys().length"
    >
      <div class="legend">
        <div :class="k" v-for="k in data.v()[0].keys()">
          <div class="color"></div>
          <div class="label">{{ t[k] || k }}</div>
        </div>
      </div>
    </slot>
    <slot name="tooltip" :tooltip="tooltip">
      <div class="tooltip-line" v-if="tooltip && tooltip.x">
        <div>
          {{ t[tooltip.label] || tooltip.label || t[tooltip.x] || tooltip.x }}
        </div>
        <div v-html="unit(window.format((metadata && metadata.format) || '.2%')(tooltip.y))"></div>
      </div>
      <div class="tooltip-line" v-if="tooltip && tooltip.date && tooltip.y">
        <div>
          {{ new Date(tooltip.date).format('day, mon, year', $root.lang).titleize() }}
        </div>
        <div v-html="unit(window.format((metadata && metadata.format) || '.2f')(tooltip.y))"></div>
      </div>
      <div class="tooltip-table" v-if="tooltip && tooltip.date && !tooltip.y">
        <div>
          {{
            '' + new Date(tooltip.date) === 'Invalid Date' || (metadata && metadata.xAxisNumeric)
              ? tooltip.date
              : new Date(tooltip.date).format('day, mon, year', $root.lang).titleize()
          }}
        </div>
        <div>
          <div v-for="(v, k) in tooltip" v-if="k !== 'date'">
            <div>{{ t[k] || k }}</div>
            <div v-html="unit(window.format((metadata && metadata.format) || '.2f')(v))"></div>
          </div>
        </div>
      </div>
    </slot>
    <slot name="fullscreen" v-if="/plot-bar/.test(type) && fullscreen">
      <board
        class="data"
        :data="
          data
            .map((v, k) => (typeof v === 'object' ? Object.assign({ sum: v.v().sum() }, v) : v))
            .map((v, k) =>
              Object.assign(
                {
                  [title.includes(',') ? title.split(',').last() : 'title']: t[k] || k,
                },
                typeof v === 'object' ? v : { [title.split(',').first()]: v },
              ),
            )
            .v()
            .sort(d =>
              new Date(this.data.keys().first()).toDateString() !== 'Invalid date' ? d : d[fullscreen_columns.first()],
            )
        "
        :metadata="{
          columns: typeof data.v().first() === 'object' ? fullscreen_columns.concat(['sum']) : fullscreen_columns,
        }"
      ></board>
    </slot>
    <div class="content" :style="{ height }" v-if="type && $root.slide === 0">
      <component
        :otherProps="otherProps"
        :is="type"
        :data="data"
        :metadata="metadata || {}"
        :options="metadata || {}"
        ref="component"
      ></component>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ['type', 'title', 'data', 'metadata', 'otherProps'],
  data() {
    return {
      tooltip: null,
      height: null,
      fullscreen: false,
    }
  },
  computed: {
    fullscreen_columns() {
      return this.data
        .map((v, k) =>
          Object.assign(
            {
              [this.title.includes(',') ? this.title.split(',').last() : 'title']: this.t[k] || k,
            },
            typeof v === 'object' ? v : { [this.title.split(',').first()]: v },
          ),
        )
        .v()
        .first()
        .keys()
    },
  },
  mounted() {
    // this.$on('tooltip', tooltip => this.tooltip = tooltip)
    if (/plot/.test(this.type) && this.$el.getBoundingClientRect().height > 400)
      this.height = this.$el.getBoundingClientRect().height - 76 + 'px'
    this.$watch('fullscreen', () => window.dispatchEvent(new Event('resize')))
  },
}
</script>
