/* eslint-disable */
export default {
  watch: {
    tooltip() {
      this.$refs
        .map($block => $block.$refs.component)
        .map($plot => {
          $plot.trigger('guideline', this.tooltip)
          $plot.components.xGuideline.value(new Date(this.tooltip))
          return null
        })
    },
  },
  methods: {
    build_look_through_detail(share) {},
    perf_risks_indicators(period) {
      let domain
      let begin_date
      if (period) {
        begin_date = new Date(this.$root.domain[1])
        begin_date.setFullYear(begin_date.getFullYear() - period[0])
      }

      if (period === '1y') {
        domain = [begin_date.toISOString().split('T')[0], this.$root.domain[1]]
      }
      if (period === '3y') {
        domain = [begin_date.toISOString().split('T')[0], this.$root.domain[1]]
      }
      if (period === 'ytd') {
        const start_of_year = new Date(new Date(this.$root.domain[1]).getFullYear(), 0, 1).toISOString().split('T')[0]
        const first_date = this.$root.x.performance.filter(d => d.date <= start_of_year).last().date
        domain = [first_date, this.$root.domain[1]]
      }
      if (period === 'inception') {
        const inception_date = this.$root.x.performance.first().date
        domain = [inception_date, this.$root.domain[1]]
      }
      if (typeof period === 'undefined') {
        domain = this.$root.domain
      }

      const raw_data = this.$root.x.performance
      const dates = raw_data.dates

      let data
      if (domain && dates) {
        data = raw_data.filter(d => d.date >= domain[0] && d.date <= domain[1])
      } else {
        data = raw_data
      }
      const tracking_error = data.tracking_error()
      const volatility = data.volatility('fund')
      const volatility_benchmark = data.volatility('benchmark')
      const perf_fund = data.performance('fund')
      const perf_benchmark = data.performance('benchmark')
      const sharpe_ratio = data.sharpe_ratio()
      const information_ratio = data.information_ratio()

      return {
        performance: [
          { title: 'performance', value: perf_fund },
          this.$root.userflow.benchmark === '' || !this.$root.userflow.benchmark
            ? {}
            : { title: 'performance_benchmark', value: perf_benchmark },
        ],
        volatility: [
          { title: 'volatility', value: volatility },
          this.$root.userflow.benchmark === '' || !this.$root.userflow.benchmark
            ? {}
            : { title: 'volatility_benchmark', value: volatility_benchmark },
        ],
        tracking_error: [
          { title: 'tracking_error', value: tracking_error },
          { title: 'information_ratio', value: information_ratio },
          { title: 'sharpe_ratio', value: sharpe_ratio },
        ],
      }
    },
  },
  data() {
    const s0 = this.$root.x.performance
    const s1 = this.$root.x.performance.filter(d => new Date(d.date) <= new Date(vm.domain[1]))
    const s2 = this.$root.x.performance
      .filter(d => new Date(d.date) <= new Date(vm.domain[1]))
      .group(d => d.date.slice(0, 4))
    const s3 = this.$root.x.performance
      .filter(d => new Date(d.date) <= new Date(vm.domain[1]))
      .group(d => d.date.slice(0, 4))
      .map((data, date, i, ds) => {
        const prev_data = ds[ds.keys()[ds.keys().indexOf(date) - 1]]
        if (prev_data) data = [prev_data.last()].concat(data)
        const r = {
          fund: data.performance('fund'),
          benchmark: data.performance('benchmark'),
        }
        r.diff = r.fund - r.benchmark
        return r
      })
    const yearly_performances = this.$root.x.performance
      .filter(d => new Date(d.date) <= new Date(vm.domain[1]))
      .group(d => d.date.slice(0, 4))
      .map((data, date, i, ds) => {
        const prev_data = ds[ds.keys()[ds.keys().indexOf(date) - 1]]
        if (prev_data) data = [prev_data.last()].concat(data)
        const r = {
          fund: data.performance('fund'),
          benchmark: data.performance('benchmark'),
        }
        r.diff = r.fund - r.benchmark
        return r
      })
    const yearly_rolling_performances = this.$root.x.performance
      .filter(d => new Date(d.date) <= new Date(vm.domain[1]))
      .group(d =>
        new Date(
          new Date(vm.domain[1]).getFullYear() -
            new Date(new Date(vm.domain[1]) - new Date(d.date)).getFullYear() +
            1970 +
            '-' +
            ('0' + (new Date(vm.domain[1]).getMonth() + 1)).slice(-2),
        ).format('MM/YYYY'),
      )
      .filter(grp => grp.length > 1)
      .map((data, date, i, ds) => {
        const prev_data = ds[ds.keys()[ds.keys().indexOf(date) - 1]]
        if (prev_data) data = [prev_data.last()].concat(data)
        const r = {
          fund: data.performance('fund'),
          benchmark: data.performance('benchmark'),
        }
        r.diff = r.fund - r.benchmark
        return r
      })
    const monthly_performances = this.$root.x.performance
      .group(d => d.date.slice(0, 7))
      .map((data, date, i, ds) => {
        const prev_data = ds[ds.keys()[ds.keys().indexOf(date) - 1]]
        if (prev_data) data = [prev_data.last()].concat(data)
        const r = {
          fund: data.performance('fund'),
          benchmark: data.performance('benchmark'),
        }
        r.diff = r.fund - r.benchmark
        return r
      })

    const data = this.$root.x.performance
    const dates = data.dates
    const first_date = new Date(dates.keys().first())
    const last_date = new Date(this.$root.screen.theme ? this.$root.domain[1] : dates.keys().last())
    const rolling_performances = {
      '1m': [last_date.minus('1 month').end('month'), last_date],
      '3m': [last_date.minus('3 month').end('month'), last_date],
      '6m': [last_date.minus('6 month').end('month'), last_date],
      ytd: [last_date.start('year'), last_date],
      '1y': [last_date.minus('1 year').end('month'), last_date],
      '3y': [last_date.minus('3 years').end('month'), last_date],
      '5y': [last_date.minus('5 years').end('month'), last_date],
      inception: [first_date, last_date],
    }.map(domain => {
      domain = domain.map(d => d.format())

      const d0 =
        dates[
          dates
            .keys()
            .reverse()
            .find(k => k <= domain[0])
        ] // same date (or previous)
      const after_d1 = dates[dates.keys().find(k => k > domain[1])] // next date
      const data_period = data.slice(d0, after_d1)
      const r = {
        fund: data_period.performance('fund'),
        benchmark: data_period.performance('fund'),
        fund_yearly: data_period.performance_annualized('fund'),
        benchmark_yearly: data_period.performance_annualized('benchmark'),
      }
      r.diff = r.fund - r.benchmark
      r.diff_yearly = r.fund_yearly - r.benchmark_yearly
      return r
    })

    const empty_perf = {
      fund: '-',
      benchmark: '-',
      diff: '-',
      fund_yearly: '-',
      benchmark_yearly: '-',
      diff_yearly: '-',
    }

    if (new Date(this.$root.domain.last()).minus('1 year') < new Date(first_date)) {
      rolling_performances['1y'] = empty_perf
    }
    if (new Date(this.$root.domain.last()).minus('3 year') < new Date(first_date)) {
      rolling_performances['3y'] = empty_perf
    }
    if (new Date(this.$root.domain.last()).minus('3 year') < new Date(first_date)) {
      rolling_performances['5y'] = empty_perf
    }
    return {
      yearly_performances,
      yearly_rolling_performances,
      monthly_performances,
      rolling_performances,
      tooltip: null,
      search: '',
      searchFN: d => !this.search || this.search.split(' ').every(s => d.v().some(v => RegExp(s, 'i').test(v))),
    }
  },
  computed: {
    look_through_pagination() {
      if (this.$root.userflow.look_through_lines) return this.$root.userflow.look_through_lines.slice(1).chunk(2)
      return []
    },
    look_through_structure() {
      const lines = this.$root.userflow.look_through_lines
      const header = [['ISIN', 'fund', 'weight', 'performance', 'volatility']]
      const pdf_table = lines.reduce((acc, share) => {
        const datas = vm.xf.allocation.data.filter(d => d.isin === share)
        const fund = vm.db.userflows.filter(d => share in d.shares).v()[0]
        if (fund) {
          acc.push([
            share,
            this.t[fund.fund_name],
            datas.last().weight_fund,
            datas.last().price / datas.first().price - 1,
            '-',
          ])
        }
        return acc
      }, header)
      return pdf_table
    },
    contributors() {
      const dataset = $root.screen.path === 'factsheet_lookthrough' ? 'contribution_lt' : 'contribution'
      if (!this.$root) return
      const metric = this.$root.query.metric || 'fund'
      const vl0 = this.$root.xf.performance.data[0]
      return this.$root.xf[dataset].data
        .map(d => ({ ...d, ...mapping[d.isin] }))
        .filter(d => d.isin !== 'UNEXPLAINED')
        .filter(d => !+d.flag_ignore_contribution)
        .group(this.id_isin)
        .reduce((acc, data, id_isin) => {
          const cumsum = key =>
            data
              .reduce((acc, d) => {
                acc.push({
                  date: d.date,
                  fund: ((acc.last() && acc.last().fund) || 0) + ((d[key + '_fund'] && +d[key + '_fund']) || 0),
                  benchmark:
                    ((acc.last() && acc.last().benchmark) || 0) +
                    ((d[key + '_benchmark'] && +d[key + '_benchmark']) || 0),
                })
                return acc
              }, [])
              .reduce((acc, d) => {
                acc[d.date] = d
                return acc
              }, {})

          const lines = data.reduce((acc, d) => {
            acc[d.date] = d
            return acc
          }, {})
          const line = (mapping[id_isin] || { name: id_isin, isin: id_isin }).map(d => d || '-')
          const last = data.find(d => d.date === this.$root.domain[1]) || {}
          if (this.id_isin === 'id') {
            line.id = last.id
          }
          line.quantity = last.quantity || 0
          line.price = last.price || 0
          line.price_change = data.last().price / data.first().price - 1
          line.weight_fund = last.weight_fund || 0
          line.weight_benchmark = last.weight_benchmark || 0
          line.pnl_fund = data.sum('pnl_fund') || 0
          line.pnl_benchmark = data.sum('pnl_benchmark') || 0
          line.lines = lines
          line.plotlines = {
            pnl: cumsum('pnl'),
            weight: lines.map(d => ({
              fund: d.weight_fund,
              benchmark: d.weight_benchmark,
            })),
            contribution: cumsum('pnl_share').map(d => ({
              fund: d.fund / vl0.fund,
              benchmark: d.benchmark / vl0.benchmark,
            })),
            price: lines.map(d => d.price),
            quantity: lines.map(d => d.quantity),
          }
          line.origin = data.first().look_throughed_isin_share || '-'
          line.contribution_fund = line.plotlines.contribution.v().last().fund
          line.contribution_benchmark = line.plotlines.contribution.v().last().benchmark
          if (metric === 'diff') {
            line.pnl = line.pnl_fund - line.pnl_benchmark
            line.weight = line.weight_fund - line.weight_benchmark
            line.contribution = line.contribution_fund - line.contribution_benchmark
          } else {
            line.pnl = line['pnl_' + metric]
            line.weight = line['weight_' + metric]
            line.contribution = line['contribution_' + metric]
          }
          acc.push(line)
          return acc
        }, [])
        .filter(d => d.contribution || d.weight)
        .sort('contribution', true)
    },
    contributors_with_without_lookthrough() {
      if (!this.$root) return
      const metric = this.$root.query.metric || 'fund'
      const vl0 = this.$root.xf.performance.data[0]

      return this.$root.xf.contribution_no_lookthrough.data
        .filter(d => d.isin !== 'UNEXPLAINED')
        .filter(d => this.$root.screen.display_all || !+d.flag_ignore_contribution)
        .group(this.id_isin)
        .reduce((acc, data, id_isin) => {
          const cumsum = key =>
            data
              .reduce((acc, d) => {
                acc.push({
                  date: d.date,
                  fund: ((acc.last() && acc.last().fund) || 0) + ((d[key + '_fund'] && +d[key + '_fund']) || 0),
                  benchmark:
                    ((acc.last() && acc.last().benchmark) || 0) +
                    ((d[key + '_benchmark'] && +d[key + '_benchmark']) || 0),
                })
                return acc
              }, [])
              .reduce((acc, d) => {
                acc[d.date] = d
                return acc
              }, {})

          const lines = data.reduce((acc, d) => {
            acc[d.date] = d
            return acc
          }, {})
          const line = (mapping[id_isin] || { name: id_isin, isin: id_isin }).map(d => d || '-')
          const last = data.find(d => d.date === this.$root.domain[1]) || {}
          if (this.id_isin === 'id') {
            line.id = last.id
          }
          line.quantity = last.quantity || 0
          line.price = last.price || 0
          line.price_change = data.last().price / data.first().price - 1
          line.weight_fund = last.weight_fund || 0
          line.weight_benchmark = last.weight_benchmark || 0
          line.pnl_fund = data.sum('pnl_fund') || 0
          line.pnl_benchmark = data.sum('pnl_benchmark') || 0
          line.lines = lines
          line.plotlines = {
            pnl: cumsum('pnl'),
            weight: lines.map(d => ({
              fund: d.weight_fund,
              benchmark: d.weight_benchmark,
            })),
            contribution: cumsum('pnl_share').map(d => ({
              fund: d.fund / vl0.fund,
              benchmark: d.benchmark / vl0.benchmark,
            })),
            price: lines.map(d => d.price),
            quantity: lines.map(d => d.quantity),
          }
          line.origin = data.first().look_throughed_isin_share || '-'
          line.contribution_fund = line.plotlines.contribution.v().last().fund
          line.contribution_benchmark = line.plotlines.contribution.v().last().benchmark
          if (metric === 'diff') {
            line.pnl = line.pnl_fund - line.pnl_benchmark
            line.weight = line.weight_fund - line.weight_benchmark
            line.contribution = line.contribution_fund - line.contribution_benchmark
          } else {
            line.pnl = line['pnl_' + metric]
            line.weight = line['weight_' + metric]
            line.contribution = line['contribution_' + metric]
          }
          acc.push(line)
          return acc
        }, [])
        .filter(d => d.contribution || d.weight)
        .sort('contribution', true)
    },
    contributors_kpi_no_lookthrough() {
      const kpi = this.contributors
        .filter(x => x.origin === this.$root.query[this.id_isin])
        .filter(x => x[this.id_isin] !== this.$root.query[this.id_isin])
        .map(x => {
          const show_benchmark =
            this.$root.userflow.benchmark &&
            x.plotlines.pnl
              .map('benchmark')
              .filter(d => d)
              .keys().length
          return [
            x.id
              ? [
                  ['name', x.name],
                  ['isin', x.isin],
                  ['id', x.id],
                ]
              : [
                  ['name', x.name],
                  ['isin', x.isin],
                ],
            [['weight', x.weight_fund]].concat([show_benchmark && ['weight_benchmark', x.weight_benchmark]]),
            [['contribution', x.contribution_fund]].concat([
              show_benchmark && ['contribution_benchmark', x.contribution_benchmark],
            ]),
            [['price_change', x.price_change]],
            [['pnl', x.pnl_fund]],
            [['quantity', x.quantity]],
            [['price', x.price]],
          ].map(d => d.filter(d => d))
        })
      if (kpi.length) return kpi
      return [[[['no_lookthrough', '-']]]]
    },
    current() {
      const metric = this.$root.query.metric || 'fund'
      const contributor =
        this.contributors.find({
          [this.id_isin]: this.$root.query[this.id_isin],
        }) || this.contributors[0]
      const date = this.tooltip || contributor.lines.v().last().date
      const line = contributor.lines[date]
      const show_benchmark =
        this.$root.userflow.benchmark &&
        contributor.plotlines.pnl
          .map('benchmark')
          .filter(d => d)
          .keys().length

      let plotlines = contributor.plotlines
      if (metric === 'fund') {
        plotlines.weight = contributor.plotlines.weight.map(plotline => {
          delete plotline.benchmark
          return plotline
        })
      } else {
        if (metric === 'benchmark') {
          plotlines.weight = contributor.plotlines.weight.map(plotline => {
            delete plotline.fund
            return plotline
          })
        } else plotlines = contributor.plotlines
      }
      return {
        plotlines,
        details: [
          contributor.id
            ? [
                ['date', date],
                ['isin', contributor.isin],
                ['id', contributor.id],
              ]
            : [
                ['date', date],
                ['isin', contributor.isin],
              ],
          [['weight', line.weight_fund]].concat([show_benchmark && ['weight_benchmark', line.weight_benchmark]]),
          [['price', line.price]],
          [['contribution', contributor.plotlines.contribution[date].fund]].concat([
            show_benchmark && ['contribution_benchmark', contributor.plotlines.contribution[date].benchmark],
          ]),
          [['position', line.quantity]],
          [['pnl', line.pnl_fund]].concat([show_benchmark && ['pnl_benchmark', line.pnl_benchmark]]),
        ].map(d => d.filter(d => d)),
      }
    },
    post() {
      if (!this.$root) return
      const { domain } = this.$root
      const metric = this.$root.query.metric || 'fund'
      const vl0 = this.$root.x.performance[this.$root.x.performance.dates[domain[0]] || 0]
      const total = {
        fund: this.$root.xf.contribution.data.sum(d => +d.pnl_share_fund) / vl0.fund,
        benchmark: this.$root.xf.contribution.data.sum(d => +d.pnl_share_benchmark) / vl0.benchmark,
      }
      if (metric === 'diff') {
        const data = this.$root.xf.contribution.map((obj, dim) =>
          dim === 'data'
            ? obj
            : obj.map((v, k) => v && v.pnl_share_fund / +vl0.fund - v.pnl_share_benchmark / +vl0.benchmark),
        )
        return { total, data }
      }
      const data = this.$root.xf.contribution.map((obj, dim) =>
        dim === 'data' ? obj : obj.map((v, k) => v / +vl0[metric]),
      )
      return { total, data }
    },
    contributors_kpi() {
      const vl0 = this.$root.xf.performance.data[0].fund
      return this.$root.xf.contribution.data
        .filter(d => (d.id || d.isin) !== 'UNEXPLAINED')
        .filter(d => d.asset_class !== 'fees')
        .map(d => ({ ...d, ...mapping[d.isin] }))
        .filter(d => !+d.flag_ignore_contribution)
        .group(this.id_isin)
        .reduce((acc, data, isin) => {
          acc.push([mapping[isin] ? mapping[isin].name : isin, data.sum('pnl_share_fund') / vl0])
          return acc
        }, [])
        .filter(d => d[1])
        .sort(d => d[1])
    },
    movements() {},
    movements_kpi() {
      return this.movements.map(d => d.concat('+.2%'))
    },
    metric() {
      return this.$root.query.metric || 'fund'
    },
    allocation_variables() {
      const { domain, query } = this.$root
      const axis = (query && query.axis) || 'weight'
      const metric = this.metric
      const data =
        domain.unique().length === 2
          ? this.$root.xf.allocation.data.filter(d => d.date === domain.last())
          : this.$root.xf.allocation.data
      return { data, axis, metric }
    },
    allocation_axis() {
      return this.$root.query && this.$root.query.evolution ? 'allocation_evolution' : 'allocation'
    },
    allocation_sum() {
      const data = this.$root.xf.allocation.date[this.$root.domain.last()]
      const fmt = vm.query.axis === 'sensitaux' ? this.$root.config.formats.sensitaux || 'pt' : '.2%'
      return this.metric === 'diff'
        ? [['active_share', data && data[(this.$root.query.axis || 'weight') + '_active_share'], fmt]]
        : [
            ['allocation', data[(this.$root.query.axis || 'weight') + '_fund'], fmt],
            ['benchmark', data[(this.$root.query.axis || 'weight') + '_benchmark'], fmt],
          ]
    },
    allocation_fund() {
      const { data, axis } = this.allocation_variables
      return data
        .filter(d => !['fx'].includes(d.asset_class) && d.weight_fund)
        .map(d => [window.mapping[d.id || d.isin] && window.mapping[d.id || d.isin].name, +d[axis + '_fund']])
        .sort(d => -d[1])
    },
    allocation_fund_kpi() {
      const fmt = vm.query.axis === 'sensitaux' ? this.$root.config.formats.sensitaux || 'pt' : '.2%'
      return this.allocation_fund.map(d => d.concat(fmt))
    },
    allocation_benchmark() {
      const fmt = vm.query.axis === 'sensitaux' ? this.$root.config.formats.sensitaux || 'pt' : '.2%'
      const { data, axis } = this.allocation_variables
      return data
        .filter(d => !['fx'].includes(d.asset_class) && d.weight_benchmark)
        .map(d => [window.mapping[d.id || d.isin] && window.mapping[d.id || d.isin].name, +d[axis + '_benchmark'], fmt])
        .sort(d => -d[1])
    },
    drawdown_kpis() {
      const raw_data = this.$root.x.performance
      const data = raw_data.filter(d => d.date >= this.$root.domain[0] && d.date <= this.$root.domain[1])
      const drawdown_fund = data.drawdown_details('fund')
      const drawdown_benchmark = data.drawdown_details('benchmark')
      const drawdown_period = [drawdown_fund, drawdown_benchmark].map(
        d => new Date(d.begin).format() + '->' + new Date(d.end).format(),
      )

      return [
        { title: 'drawdown', value: -drawdown_fund.drawdown },
        { title: 'drawdown_benchmark', value: -drawdown_benchmark.drawdown },
        { title: '', value: '' },
        { title: 'period', value: drawdown_period[0] },
        { title: 'period_benchmark', value: drawdown_period[1] },
        {
          title: 'recovery',
          value: isNaN(new Date(drawdown_fund.recovery))
            ? drawdown_fund.recovery
            : new Date(drawdown_fund.recovery).format('day, mon, year', this.$root.lang),
        },
        {
          title: 'recovery_benchmark',
          value: isNaN(new Date(drawdown_benchmark.recovery))
            ? drawdown_benchmark.recovery
            : new Date(drawdown_benchmark.recovery).format('day, mon, year', this.$root.lang),
        },
      ]
    },
    sortMonths() {
      if (this.$root.lang === 'fr')
        return d =>
          ['Janv', 'Févr', 'Mars', 'Avr', 'Mai', 'Juin', 'Juil', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'].indexOf(d.x)
      return d => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].indexOf(d.x)
    },
    kpis() {
      return this.perf_risks_indicators()
    },
    performance_historical() {
      const data = this.$root.x.performance

      if (!data || data.length === 0) {
        console.error('Data is empty or not available')
        return {}
      }

      // Try to find the index of the first element with a non-null benchmark
      // const firstIndex = data.findIndex(item => item.benchmark !== null);
      const firstIndex = -1

      // If an item with a non-null benchmark is found, slice the data from that point, otherwise use the full data
      const slicedData = firstIndex !== -1 ? data.slice(firstIndex) : data

      const first = slicedData[0]

      return slicedData.reduce((acc, v) => {
        const result = {
          fund: (+v.fund / +first.fund) * 100,
          benchmark: v.benchmark ? (+v.benchmark / +first.benchmark) * 100 : null,
          benchmark2: v.benchmark2 ? (+v.benchmark2 / +first.benchmark2) * 100 : null,
        }

        acc[v.date] = Object.fromEntries(Object.entries(result).filter(([key, value]) => value !== null))

        return acc
      }, {})
    },
    // performance_historical_legacy () {
    //   const data = this.$root.x.performance
    //   const first = data.first()
    //   return data.reduce((acc, v) => {
    //     acc[v.date] = {
    //       fund: (+v.fund / +first.fund) * 100,
    //       benchmark: v.benchmark ? (+v.benchmark / +first.benchmark) * 100 : null,
    //       benchmark2: v.benchmark2 ? (+v.benchmark2 / +first.benchmark2) * 100 : null
    //     };

    //     acc[v.date] = Object.fromEntries(
    //       Object.entries(result).filter(([key, value]) => value !== null)
    //     );

    //     return acc;
    //   }, {});
    //   console.log("retretret", ret)
    //   return ret
    // }

    performance_yearly() {
      const metric = this.$route.query.metric || 'fund'
      return this.yearly_performances.map(d => d[metric])
    },
    performance_monthly() {
      const metric = this.$route.query.metric || 'fund'
      const year = (this.$route.query.domain || this.yearly_performances.keys()[0]).slice(0, 4)
      return (1).upto(12).reduce((acc, num) => {
        const month = year + '-' + ('0' + num).slice(-2)
        if (!this.monthly_performances[month]) return acc
        const date = new Date(month).format('mon', this.$root.lang).titleize()
        acc[date] = this.monthly_performances[month][metric]
        return acc
      }, {})
    },
  },
}
