import { getFactsheetQuery, getLastAllocationDate } from '../query'
import { transform } from '../transform'

export default {
  async mounted() {
    this.loaded = false
    $root.refresh = $root.refresh + 1
    const isinShare = this.$root.$route.fullPath.split('/')[2].split('-')[1]
    const domainString = this.$root.$route.query.domain || $root.domain.join('|')
    const fundId = this.$root.userflow.fundId
    const lang = this.$root.lang
    const domainAllocation = await getLastAllocationDate(isinShare, domainString)
    const inceptionDomain = $root.userflow.fund_inception + '|' + domainAllocation
    const d = await getFactsheetQuery(fundId, isinShare, domainString, domainAllocation, lang, inceptionDomain)
    const res = transform(d)
    Object.assign(this.$data, res)
    this.loaded = true
  },
}
