export default {
  table: {
    characteristics: [
      ['isin', 'bloomberg_code', 'fund_inception', 'unit_inception'],
      [
        "() => this.$route.params.userflow.split('-')[1]",
        '() => $root.share.bloomberg_code',
        "() => new Date($root.share.fund_inception).format('DD/MM/YYYY')",
        "() => new Date($root.share.share_inception).format('DD/MM/YYYY')",
      ],
      ['legal_status', 'Share Currency', 'horizon', 'management_company'],
      [
        '() => $root.userflow.fund_type',
        '() => $root.share.share_currency',
        "() => $root.userflow.horizon + ''",
        '() => $root.userflow.management_company',
      ],
      ['depositary', 'valuation', 'cutoff', 'settlement'],
      [
        '() => $root.userflow.fund_admin',
        '() => $root.userflow.valuation_frequency',
        '() => $root.userflow.cut_off',
        '() => $root.userflow.settlement',
      ],
      ['management_fee', 'performance_fee', 'initial_charge', 'redemption_fee'],
      [
        "() => window.format('.2%')($root.share.management_fee)",
        '() => $root.share.performance_fee',
        "() => window.format('.2%')($root.share.initial_charge)",
        "() => window.format('.2%')($root.share.redemption_fee)",
      ],
    ],
    characteristics_de: [
      ['isin', 'wkn', 'bloomberg_code', 'currency'],
      [
        "() => this.$route.params.userflow.split('-')[1]",
        '() => $root.share.wkn_code',
        '() => $root.share.bloomberg_code',
        '() => $root.share.share_currency',
      ],
      ['horizon', 'legal_status', 'unit_inception', 'management_company'],
      [
        "() => $root.userflow.horizon + ''",
        '() => $root.userflow.fund_type',
        "() => new Date($root.share.share_inception).format('DD.MM.YYYY')",
        '() => $root.userflow.management_company',
      ],
      ['depositary', 'valuation', 'cutoff', 'settlement'],
      [
        '() => $root.userflow.fund_admin',
        '() => $root.userflow.valuation_frequency',
        '() => $root.userflow.cut_off',
        '() => $root.userflow.settlement',
      ],
      ['management_fee', 'performance_fee', 'initial_charge', 'redemption_fee'],
      [
        "() => window.format('.2%')($root.share.management_fee)",
        '() => $root.share.performance_fee',
        "() => window.format('.2%')($root.share.initial_charge)",
        "() => window.format('.2%')($root.share.redemption_fee)",
      ],
    ],
    cumulated_performance: [
      [
        null,
        'performance',
        'performance',
        'performance',
        'performance_cumulated',
        'performance',
        'performance',
        'performance',
        'performance_annualized',
        'performance',
        'performance',
      ],
      [null, '1m', 'ytd', '1y', '3y', '5y', 'inception', '3y', '5y', 'inception'],
      [
        'fund',
        'performance.1m.fund',
        'performance.ytd.fund',
        'performance.1y.fund',
        'performance.3y.fund',
        'performance.5y.fund',
        'performance.inception.fund',
        'performance_annualized.3y.fund',
        'performance_annualized.5y.fund',
        'performance_annualized.inception.fund',
      ],
      [
        'benchmark',
        'performance.1m.benchmark',
        'performance.ytd.benchmark',
        'performance.1y.benchmark',
        'performance.3y.benchmark',
        'performance.5y.benchmark',
        'performance.inception.benchmark',
        'performance_annualized.3y.benchmark',
        'performance_annualized.5y.benchmark',
        'performance_annualized.inception.benchmark',
      ],
      [
        'diff',
        'performance.1m.diff',
        'performance.ytd.diff',
        'performance.1y.diff',
        'performance.3y.diff',
        'performance.5y.diff',
        'performance.inception.diff',
        'performance_annualized.3y.diff',
        'performance_annualized.5y.diff',
        'performance_annualized.inception.diff',
      ],
      [
        'rank',
        '() => $root.share.mstar_1m',
        '() => $root.share.mstar_ytd',
        '() => $root.share.mstar_1y',
        '() => $root.share.mstar_3y',
        '() => $root.share.mstar_5y',
        '() => $root.share.mstar_crea',
      ],
    ],
    cumulated_performance_no_benchmark: [
      [
        null,
        'performance',
        'performance',
        'performance',
        'performance_cumulated',
        'performance',
        'performance',
        'performance',
        'performance_annualized',
        'performance',
        'performance',
      ],
      [null, '1m', 'ytd', '1y', '3y', '5y', 'inception', '3y', '5y', 'inception'],
      [
        'fund',
        'performance.1m.fund',
        'performance.ytd.fund',
        'performance.1y.fund',
        'performance.3y.fund',
        'performance.5y.fund',
        'performance.inception.fund',
        'performance_annualized.3y.fund',
        'performance_annualized.5y.fund',
        'performance_annualized.inception.fund',
      ],
      [
        'rank',
        '() => $root.share.mstar_1m',
        '() => $root.share.mstar_ytd',
        '() => $root.share.mstar_1y',
        '() => $root.share.mstar_3y',
        '() => $root.share.mstar_5y',
        '() => $root.share.mstar_crea',
      ],
    ],
    cumulated_performance_no_morning_star_no_benchmark: [
      [
        null,
        'performance',
        'performance',
        'performance',
        'performance_cumulated',
        'performance',
        'performance',
        'performance',
        'performance_annualized',
        'performance',
        'performance',
      ],
      [null, '1m', 'ytd', '1y', '3y', '5y', 'inception', '3y', '5y', 'inception'],
      [
        'fund',
        'performance.1m.fund',
        'performance.ytd.fund',
        'performance.1y.fund',
        'performance.3y.fund',
        'performance.5y.fund',
        'performance.inception.fund',
        'performance_annualized.3y.fund',
        'performance_annualized.5y.fund',
        'performance_annualized.inception.fund',
      ],
    ],
    cumulated_performance_no_morning_star: [
      [
        null,
        'performance',
        'performance',
        'performance',
        'performance_cumulated',
        'performance',
        'performance',
        'performance',
        'performance_annualized',
        'performance',
        'performance',
      ],
      [null, '1m', 'ytd', '1y', '3y', '5y', 'inception', '3y', '5y', 'inception'],
      [
        'fund',
        'performance.1m.fund',
        'performance.ytd.fund',
        'performance.1y.fund',
        'performance.3y.fund',
        'performance.5y.fund',
        'performance.inception.fund',
        'performance_annualized.3y.fund',
        'performance_annualized.5y.fund',
        'performance_annualized.inception.fund',
      ],
      [
        'benchmark',
        'performance.1m.benchmark',
        'performance.ytd.benchmark',
        'performance.1y.benchmark',
        'performance.3y.benchmark',
        'performance.5y.benchmark',
        'performance.inception.benchmark',
        'performance_annualized.3y.benchmark',
        'performance_annualized.5y.benchmark',
        'performance_annualized.inception.benchmark',
      ],
      [
        'diff',
        'performance.1m.diff',
        'performance.ytd.diff',
        'performance.1y.diff',
        'performance.3y.diff',
        'performance.5y.diff',
        'performance.inception.diff',
        'performance_annualized.3y.diff',
        'performance_annualized.5y.diff',
        'performance_annualized.inception.diff',
      ],
    ],
    indicators: [
      ['aum', "() => window.format('.1fs€')($root.share.analytics.aum.aum_fund_in_fund_ccy)"],
      [
        // on devrait l'avoir
        'stock_exposition',
        "() => window.format('.1%')($root.userflow.expo_actions? parseFloat($root.userflow.expo_actions) : '-')",
      ],
      [
        // deja dans la requete  positionCount(domain: $domain)
        'lines',
        "() => ' ' + $root.share.positionCount",
      ],
      [
        // additioner weight_active_share_benchmark
        'active_share_portefeuille',
        "() => window.format('.1%')($root.share.active_share)",
      ],
      [
        // still to format
        'mean_cap_size',
        '() => $root.share.capi_moyenne',
      ],
      ['() => $root.userflow.ratio1', '() => $root.userflow.ratio1_data'],
      ['() => $root.userflow.ratio2', '() => $root.userflow.ratio2_data'],
    ],
    indicators_taux: [
      ['aum', "() => window.format('.1fs€')($root.share.analytics.aum.aum_fund_in_fund_ccy)"],
      ['nb_emetteur', "() => ' ' + $root.share.libEmetteurCount"],
      [
        'yield_to_maturity',
        "() => window.format('.2fs')($root.share.yield_to_maturity?parseFloat($root.share.yield_to_maturity):$root.share.yield_to_maturity)",
      ],
      [
        'effective_yield',
        "() => window.format('.2fs')($root.share.effective_yield?parseFloat($root.share.effective_yield):$root.share.effective_yield)",
      ],
      [
        'rate_sensitivity',
        "() => window.format('.2fs')($root.share.rate_sensitivity?parseFloat($root.share.rate_sensitivity):$root.share.rate_sensitivity)",
      ],
      [
        'mean_maturity',
        "() => window.format('.2fs')($root.share.mean_maturity?parseFloat($root.share.mean_maturity):$root.share.mean_maturity)",
      ],
      ['mean_rating', '() => $root.share.mean_rating'],
    ],
    indicators_lt: [
      ['aum', "() => window.format('.1fs€')($root.share.analytics.aum.aum_fund_in_fund_ccy)"],
      [
        'stock_exposition',
        "() => window.format('.1%')($root.userflow.expo_actions? parseFloat($root.userflow.expo_actions) : '-')",
      ],
      [
        'lines',
        "() => ' ' + $root.xf.allocation_lt.data.filter(d => d.valuation !== 0 && d.date === $root.domain.last()).filter(d => ($root.x.mapping.find(m => m.isin === d.isin) || {}).asset_type === 'Lignes').length",
      ],
      [
        'active_share_portefeuille',
        "() => window.format('.1%')($root.xf.allocation_lt.data.filter(d => d.date === $root.domain.last()).map(d => Math.max(0, d.weight_fund - d.weight_benchmark)).sum())",
      ],
      ['mean_cap_size', "() => '' + $root.userflow.capi_moyenne"],
      ['() => $root.userflow.ratio1', '() => $root.userflow.ratio1_data'],
      ['() => $root.userflow.ratio2', '() => $root.userflow.ratio2_data'],
    ],
    indicators_lt_no_benchmark: [
      ['aum', "() => window.format('.1fs€')($root.share.analytics.aum.aum_fund_in_fund_ccy)"],
      [
        'stock_exposition',
        "() => window.format('.1%')($root.userflow.expo_actions? parseFloat($root.userflow.expo_actions) : '-')",
      ],
      [
        'lines',
        "() => ' ' + $root.xf.allocation_lt.data.filter(d => d.valuation !== 0 && d.date === $root.domain.last()).filter(d => ($root.x.mapping.find(m => m.isin === d.isin) || {}).asset_type === 'Lignes').length",
      ],
      ['mean_cap_size', "() => '' + $root.userflow.capi_moyenne"],
      ['() => $root.userflow.ratio1', '() => $root.userflow.ratio1_data'],
      ['() => $root.userflow.ratio2', '() => $root.userflow.ratio2_data'],
    ],
    indicators_no_benchmark: [
      ['aum', "() => window.format('.1fs€')($root.share.analytics.aum.aum_fund_in_fund_ccy)"],
      [
        'stock_exposition',
        "() => window.format('.1%')($root.userflow.expo_actions? parseFloat($root.userflow.expo_actions) : '-')",
      ],
      ['lines', "() => ' ' + $root.share.positionCount"],
      ['mean_cap_size', "() => '' + $root.userflow.capi_moyenne"],
      ['() => $root.userflow.ratio1', '() => $root.userflow.ratio1_data'],
      ['() => $root.userflow.ratio2', '() => $root.userflow.ratio2_data'],
    ],
    risk_indicators: [
      ['', 'volatility_fund', 'volatility_benchmark', 'tracking_error', 'information_ratio', 'sharpe_ratio'],
      [
        '1y',
        'volatility.1y.fund',
        'volatility.1y.benchmark',
        'tracking_error.1y',
        'information_ratio.1y',
        'sharpe_ratio.1y',
      ],
      [
        '3y',
        'volatility.3y.fund',
        'volatility.3y.benchmark',
        'tracking_error.3y',
        'information_ratio.3y',
        'sharpe_ratio.3y',
      ],
    ],
    risk_indicators_no_benchmark: [
      ['', 'volatility_fund', 'sharpe_ratio'],
      ['1y', 'volatility.1y.fund', 'sharpe_ratio.1y'],
      ['3y', 'volatility.3y.fund', 'sharpe_ratio.3y'],
    ],
  },
}
