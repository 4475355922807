export default {
  methods: {
    metadata_other(dimension) {
      return dimension === 'currency' ? 'other' : 'Cash_Autres'
    },
    metadata_limit(dimension) {
      return dimension === 'rating_sb' ? 8 : 6
    },
  },
  computed: {
    yearly_rolling_performances_reflex_de() {
      return this.yearly_rolling_performances.map(d => d.filter((d, i) => i === 'fund'))
    },
    main_movements() {
      if (vm.share.achats || vm.share.ventes) {
        return vm.share.achats.split(',').length > vm.share.ventes.split(',').length
          ? vm.share.achats.split(',').map((d, k) => [d, vm.share.ventes.split(',')[k] || ''])
          : vm.share.ventes.split(',').map((d, k) => [vm.share.achats.split(',')[k] || '', d])
      }
      if (this.movements.length === 0) return []
      const ret = top_bottom(this.movements, 3).map(d => [
        (this.$root.x.allocation
          .filter(f => f.isin === d[0] && f.weight_fund > 0)
          .filter(
            f => f.date <= this.$root.domain[0] && f.date >= new Date(this.$root.domain[0]).minus('1 month').format(),
          ).length > 0 || !d[0]
          ? ''
          : '(+) ') + (((d[0] && mapping[d[0]]) || {}).name || d[0]),
        (this.$root.x.allocation.filter(f => f.date >= this.$root.domain[1] && f.isin === d[2] && f.weight_fund > 0)
          .length > 0 || !d[2]
          ? ''
          : '(-) ') + (((d[2] && mapping[d[2]]) || {}).name || d[2]),
      ])
      return ret
    },
    // main_movements() {
    //   if (vm.share.achats || vm.share.ventes)
    //     return vm.share.achats.split(',').length > vm.share.ventes.split(',').length
    //       ? vm.share.achats.split(',').map((d, k) => [d, vm.share.ventes.split(',')[k] || ''])
    //       : vm.share.ventes.split(',').map((d, k) => [vm.share.achats.split(',')[k] || '', d])
    //   if (this.movements.length === 0) return []
    //   return top_bottom(this.movements, 3).map(d => [
    //     (this.$root.x.allocation
    //       .filter(f => f.isin === d[0] && f.weight_fund > 0)
    //       .filter(f => f.date <= this.$root.domain[0] && f.date >= new Date(this.$root.domain[0]).minus('1 month').format()).length > 0 || !d[0]
    //       ? ''
    //       : '(+) ') + (((d[0] && mapping[d[0]]) || {}).name || d[0]),
    //     (this.$root.x.allocation.filter(f => f.date >= this.$root.domain[1] && f.isin === d[2] && f.weight_fund > 0).length > 0 || !d[2] ? '' : '(-) ') +
    //       (((d[2] && mapping[d[2]]) || {}).name || d[2]),
    //   ])
    // },
    main_contributors() {
      try {
        if (vm.share.contributeurs || vm.share.detracteurs) {
          if (vm.share.contributeurs.split(',').length > vm.share.detracteurs.split(',').length) {
            return vm.share.contributeurs
              .split(',')
              .chunk(2)
              .map((d, k) => [d, vm.share.detracteurs.split(',').chunk(2)[k] || ''].flat())
          } else {
            return vm.share.detracteurs
              .split(',')
              .chunk(2)
              .map((d, k) => [vm.share.contributeurs.split(',').chunk(2)[k] || '', d].flat())
          }
        }
      } catch (e) {
        return [['error nx-pack', '0', 'error nx-pack', '0']]
      }
      const positive = this.contributors_kpi.sort('-1')
      const negative = this.contributors_kpi.sort('1')
      const ret = Array(3)
        .fill()
        .map((d, i) =>
          [positive[i] || ['', ''], negative[i] || ['', '']]
            .filter(d => d)
            .map(d => [d[0], window.format('+.1f')(d[1] * 100)])
            .flatten(),
        )
      return ret
    },
    main_lines() {
      const dim = this.$root.userflow.dimensions_main_lines ? 'dimensions_main_lines' : 'dimensions'
      return this.contributors
        .filter(
          d =>
            d.asset_class === 'Actions' &&
            (d.asset_type === 'Lignes' || (d.asset_type === 'OPCVM' && d.weight_fund >= 0.1)),
        )
        .sort('-weight')
        .slice(0, 10)
        .map(d => [
          d.name,
          window.format('.1%')(d.weight),
          this.t[d[this.$root.userflow[dim][0]]] + ' / ' + this.t[d[this.$root.userflow[dim][1]]],
        ])
    },
    performance_historical_asof_2() {
      if (!this.has_enough_perf_data) {
        return this.$root.x.performance
          .reduce((acc, v) => {
            acc[v.date] = { fund: v.fund }
            return acc
          }, {})
          .filter((v, k) => k <= this.$root.domain[1])
      }
      const vls = this.performance_historical.filter((v, k) => k <= this.$root.domain[1])
      for (const date in vls) {
        if (vls.hasOwnProperty(date)) {
          // eslint-disable-line  no-prototype-builtins
          delete vls[date].benchmark
        }
      }
      const first = vls.v()[0]
      return vls.reduce((acc, v, k, ds) => {
        acc[k] = v.map((v, k) => +v / first[k] - 1)
        return acc
      }, {})
    },
    performance_historical_asof() {
      if (!this.has_enough_perf_data) {
        return this.$root.x.performance
          .reduce((acc, v) => {
            acc[v.date] = { fund: v.real_nav }
            return acc
          }, {})
          .filter((v, k) => k <= this.$root.domain[1])
      }
      const vls = this.performance_historical.filter((v, k) => k <= this.$root.domain[1])
      const first = vls.v()[0]
      return vls.reduce((acc, v, k, ds) => {
        acc[k] = v.map((v, k) => +v / first[k] - 1)
        return acc
      }, {})
    },
    performance_yearly_asof_only_fund() {
      return this.yearly_performances.filter((v, k) => k <= this.$root.domain[1]).map(d => ({ fund: d.fund }))
    },
    performance_yearly_asof_isr() {
      const data = this.yearly_performances
        .filter((v, k) => k <= this.$root.domain[1])
        .map(d => (d.benchmark === 0 ? { fund: d.fund } : { fund: d.fund, benchmark: d.benchmark }))
      const expectedKey = data.keys().sort().slice(-6)
      return data.filter((d, k) => expectedKey.includes(k))
    },
    performance_yearly_asof() {
      return this.yearly_performances
        .filter((v, k) => k <= this.$root.domain[1])
        .map(d => (d.benchmark === 0 ? { fund: d.fund } : { fund: d.fund, benchmark: d.benchmark }))
    },
    performance_5y_asof() {
      const vls = this.performance_historical.filter(
        (v, k) => k <= this.$root.domain[1] && k >= new Date(this.$root.domain[1]).minus('5 years').format(),
      )
      const first = vls.v()[0]
      return vls.reduce((acc, v, k, ds) => {
        acc[k] = v.map((v, k) => +v / first[k] - 1)
        return acc
      }, {})
    },
    has_enough_perf_data() {
      if ($root.userflow.dedicated === 1) return true
      return new Date(this.$root.domain[1]).minus('year') >= new Date(this.$root.share.share_inception)
    },
    plotLineDecimal() {
      const perf = this.performance_historical_asof
      const fund_perf = Object.values(perf).map(p => p.fund)
      if (!fund_perf.length) return '.0%'
      let decimal_format = this.has_enough_perf_data ? '.0%' : '.0f'
      const max = Math.max(...fund_perf)
      const min = Math.min(...fund_perf)
      // Increase decimal if the y axis interval is too small
      if (format(decimal_format)(max) === format(decimal_format)(min)) {
        decimal_format = this.has_enough_perf_data ? '.2%' : '.2f'
      }
      return this.has_enough_perf_data ? decimal_format : decimal_format + $root.share.share_currency
    },
  },
  data() {
    // const yearly_rolling_performances = this.$root.x.performance
    //   .filter(d => new Date(d.date) <= new Date(vm.domain[1]))
    //   .group(d =>
    //     new Date(
    //       new Date(vm.domain[1]).getFullYear() -
    //         new Date(new Date(vm.domain[1]) - new Date(d.date)).getFullYear() +
    //         1970 +
    //         '-' +
    //         ('0' + (new Date(vm.domain[1]).getMonth() + 1)).slice(-2)
    //     ).format('MM/YYYY')
    //   )
    //   .filter(grp => grp.length > 1)
    //   .map((data, date, i, ds) => {
    //     const prev_data = ds[ds.keys()[ds.keys().indexOf(date) - 1]]
    //     if (prev_data) data = [prev_data.last()].concat(data)
    //     const r = {
    //       fund: data.performance('fund'),
    //       benchmark: data.performance('benchmark')
    //     }
    //     r.diff = r.fund - r.benchmark
    //     return r
    //   })
  },
}
