<template>
  <builder-block class="grk-srri" :data="data" :options="options" :context="context">
    <div class="flex flex-col">
      <div class="flex w-full flex-row items-center gap-4" v-if="srri">
        <div class="min-w-[70px]">{{ translate('risk_scale') }}</div>
        <div class="flex flex-row gap-0.5">
          <div
            class="flex h-4 w-4 items-center justify-center rounded-full text-white"
            :class="i === srri ? 'bg-black' : 'bg-secondary'"
            v-for="i in [1, 2, 3, 4, 5, 6, 7]"
          >
            <span>{{ i }}</span>
          </div>
        </div>
      </div>
      <div class="flex w-full flex-row items-center gap-4" v-if="morningstarRating">
        <div class="min-w-[70px]">{{ translate('morningstar') }}</div>
        <div class="flex flex-row gap-0.5">
          <div class="h-4 w-4" v-for="i in morningstarRating">
            <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z"
                fill="#FEDB3D"
                fill-rule="evenodd"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </builder-block>
</template>
<script setup lang="ts">
const { data, options, context } = defineProps(['data', 'options', 'context'])
function translate(value: string) {
  return context.translations[value] || value
}
const srri = +data?.srri
const morningstarRating = +data?.ms_stars
</script>

<style scoped></style>
