<script>
export const additions = { theme: '.pdf-page' }
// ["info", "performance", "allocation", "management", "contribution", "details", "look_through"]
import mixin1 from '../../mixins/global.js'
import mixin2 from '../../mixins/info.js'
import mixins from '../../mixins/old-mixins.js'
import mixin9 from '../../mixins/factsheet.js'
import mixin_dr from '../../mixins/global-on-mounted.js'

export default {
  mixins: [mixin1, mixin2, mixins, mixin9, mixin_dr],
  data() {
    return {
      loaded: false,
    }
  },
}
</script>

<template lang="pug">
.pdf-page(v-if="loaded").grid
  pdf-header
  .row.expand
    .column
      .stars.risk-scale
        .label {{ t.risk_scale }}
        .star(:class="{ active: i+'' === srri }" v-for="i in 7") {{ i }}
      .stars.morningstar(v-if="$root.share.ms_stars")
        .label {{ t.morningstar }}
        .star(v-for="i in +$root.share.ms_stars")
          <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z" fill="#FEDB3D" fill-rule="evenodd"/></svg>
      brick(type="pdf-key-performances" v-if="has_enough_perf_data" :otherProps="keyPerformances"
     )
      .managers
        .profile(v-for="manager in managers")
          img(:src="manager.img")
          div {{ manager.firstName }}
          div.surname {{ manager.lastName}}
      brick.comment(:data="comment" type="pdf-text")
    .column(:class="{ 'space-between': has_enough_perf_data }")
      div
        brick.orientation(:data="orientation")
        h2.performance_and_risk {{ t.performance_and_risk }}
        brick.italic(data="Disclaimer_Perf")
        brick.italic(v-if="$root.userflow?.benchmark" :data="benchmark_disclaimer" type="pdf-text")
      brick(title="performance_inception" :class="{ 'not-enough-data': !has_enough_perf_data }" type="line" data="computed.performance_historical_asof" :metadata="{ format: plotLineDecimal }" @rebind="$event.yAxis._computeWidth = () => 30")
        .nav
          .value(v-html="unit(window.format('nav')(static.real_nav)+ shareCurrency)")
          .name {{ t.nav }}
      brick(data="nxpack.Disclaimer_12mois" v-if="!has_enough_perf_data")
      brick(title="performance_yearly" type="vbar" data="computed.performance_yearly_asof"  :metadata="{ limit: yearlyPerformancesLimit, format: '.1%', variant: 'clustered' }" v-if="has_enough_perf_data")
      div
        brick(title="performance_cumulated_annualized" :data="cumulatedPerformanceTable" v-if="has_enough_perf_data" )
        brick.morningstar_disclaimer(:data="$root.share.mstar_category" v-if="has_enough_perf_data")
      brick(title="risk_indicators" :data="riskIndicatorsTable" type="pdf-table" v-if="has_enough_perf_data" :otherProps="analyticsInput")
.pdf-page(v-if="loaded").grid
  pdf-header
  h2.portfolio {{ t.portfolio }}
  .row
    brick.main_lines.half(title="main_lines" type="pdf-table" :data="mainLinesObligation")
    brick(v-if="!sectorIgbData" :title="$root.userflow.dimensions[0]" type="hbar" :data="getGroupedAllocations($root.userflow.dimensions[0])" :metadata="{ format: '.1%', limit: 15, other_key: metadata_other($root.userflow.dimensions[0]), sort: d => -d.value + (d.key === 'Cash_Autres' ? 1000 : 0) }")
    brick(v-else :title="$root.userflow.dimensions[0]" type="hbar" :data="sectorIgbData" :metadata="{ format: '.1%', limit: 15, sort: d => -d.value + (d.key === 'Cash_Autres' ? 1000 : 0)}")
    brick(:title="$root.userflow.dimensions[1]" type="hbar" :data="getGroupedAllocations($root.userflow.dimensions[1])" :metadata="{ format: '.1%', limit: 15, other_key: metadata_other($root.userflow.dimensions[1]), sort: d => -d.value + (d.key === 'Cash_Autres' ? 1000 : 0) }")
  h2.profile {{ t.profile }}
  .row.profile_raw
    .half
      brick.profile_indicators(:otherProps="{filterIndicators:true}" :title="'profile_indicators,at,' + $root.period[1]" :data="$root.userflow.benchmark ? 'nxpack.indicators_taux' : 'nxpack.indicators_taux'")
      brick(title="profile_esg" type="pdf-profile-esg" v-if="$root.userflow.ratio_esg1_fonds || $root.userflow.ratio_esg2_fonds")
    brick(:title="$root.userflow.dimensions[2]" type="pie" :data="getGroupedAllocations($root.userflow.dimensions[2])" :metadata="{ format: '.1%', limit: metadata_limit($root.userflow.dimensions[2]), other_key: metadata_other($root.userflow.dimensions[2]), sort: metadataSortCapi($root.userflow.dimensions[2]) }")
    brick(:title="$root.userflow.dimensions[3]" type="pie" :data="getGroupedAllocations($root.userflow.dimensions[3])" :metadata="{ format: '.1%', limit: metadata_limit($root.userflow.dimensions[3]), other_key: metadata_other($root.userflow.dimensions[3]), sort: metadataSortCapi($root.userflow.dimensions[3]) }")
  .separator
  h2.characteristics {{ t.characteristics }}
  .row
    brick.characteristics-table(data="nxpack.characteristics")
  pdf-footer
</template>
