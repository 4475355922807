export const getFactsheetQuery = async (
  isinShare,
  domainString,
  lang,
  lookthrough = 0,
  top10Oblig,
  movementsFilterName = '',
) => {
  const allocationQueryPart = lookthrough
    ? `allocation: lookthrough(domain: $domain)`
    : `allocation(domain: $domainAllocation)`
  const allocationArgsPart = lookthrough ? `` : `$domainAllocation:  Domain!`
  const linesPart = lookthrough
    ? `lines_top10_lookthrough(domain: $domain, lookthroughLevel: 4) {
    name
    weight_fund
    dimension0
    dimension1
    dimension2
  }`
    : `lines_top10(domain: $domain) {
    name
    weight_fund
    dimension0
    dimension1
    dimension2
  }`
  const top10ObligPart = top10Oblig
    ? `linesTop10Obligation: lines_top10_obligation(domain: $domain) {
    dimension0
    dimension1
    dimension2
    name
    weight_fund
  }`
    : ''
  const transactionPart = lookthrough
    ? `transactions: transactions_lookthrough(domain: $domain, movementsFilter: $movementsFilter)`
    : `transactions(domain: $domain, movementsFilter: $movementsFilter)`
  const query = `
  query FactsheetQuery(
    $fuid: FuidInput!
    $isinShare: String!
    $domain: Domain!
    $movementsFilter: String
    $lang: Lang!
    ${allocationArgsPart}
  ) {
    businessData {
      
      nxPackTables {
        table
        name
      }
      share(shareId: $isinShare) {
        mainContributors(domain: $domain){
          fuid
          name
          contribution
        }
        linesTop10:${linesPart}
        ${top10ObligPart}
        fund_inception
        share_inception
        ${transactionPart}{
          all {
            name
            type
            var
            id
          }
        }
        ${allocationQueryPart} {
          date
          fundId
          shareId
          name
          asset_class
          asset_currency
          quantity
          price
          fixing
          valuation
          fuid
          asof
          date_d_echeance
          date_d_echeance_with_call
          weight_fund
          weight_benchmark
          weight_diff_benchmark
          weight_active_share_benchmark
          axis
          exposure_fund
          contrib_sensi_fund
          contrib_ytm_fund
          contrib_deltaaction_fund
          contrib_spreadobl_fund
          exposure_benchmark
          contrib_sensi_benchmark
          contrib_ytm_benchmark
          contrib_deltaaction_benchmark
          contrib_spreadobl_benchmark
        }
        characteristics {
          capi_moyenne
          srri
          benchmark_disclaimer
          share_letter
          fund_type
          pea_eligible
          management_company
          managers
          amf_classification
          orientation
          bloomberg_code
          settlement
          minimum_initial_subscription
          initial_charge
          redemption_fee
          management_fee
          performance_fee
          custodian
          fund_admin
          assignment_distributable_sums
          valuation_frequency
          pdfparamlimitnumberofyear
          category
        }
        slug
        benchmark {
          id
          label
        }
        share_currency
        analytics {
          domainHistorical {
            date
            fund
            benchmark
            benchmark2
            benchmark3
          }
          yearlyPerformances(domain: $domain) {
            year
            fund
            benchmark
            benchmark2
            benchmark3
            month
            year
            diff
            risk_free
            real_nav
            aum_fund_in_fund_ccy
          }
          monthlyPerformances(domain: $domain) {
            date
            fund
            benchmark
            benchmark2
            benchmark3
            month
            year
            diff
            risk_free
            real_nav
            aum_fund_in_fund_ccy
          }
          domainPerformance {
            date
            fund
            benchmark
            benchmark2
            benchmark3
            month
            year
            diff
            risk_free
            real_nav
            aum_fund_in_fund_ccy
          }
          netAssetValue
          drawdownDetails {
            drawdown
            begin
            begin_nav
            end
            end_nav
            recovery
            duration
          }
          aum(domain: $domain){
            aum_fund_in_fund_ccy
            aum_share_in_fund_ccy
            date
            shareId
          }
          sharpe_ratio1y: domainSharpeRatio(domain: $domain, period: "1y")
          sharpe_ratio3y: domainSharpeRatio(domain: $domain, period: "3y")
          information_ratio1y: informationRatio(domain: $domain,period: "1y")
          information_ratio3y: informationRatio(domain: $domain, period: "3y")
          tracking_error1y: trackingError(domain: $domain,period: "1y")
          tracking_error3y: trackingError(domain: $domain,period: "3y")
          volatility1y: volatility(domain: $domain, period: "1y") {
            fund
            benchmark
          }
          volatility3y: volatility(domain: $domain, period: "3y") {
            fund
            benchmark
          }
        }
  
        comment(domain: $domain, lang: $lang) 
  
        asof
        axis_of_analysis
        contribution(domain: $domain) {
          fuid
          name
          contribution_fund
        }
      }
    }
    referential {
      share(fuid: $fuid) {
        otherFundCharacteristics
      }
    }
    breakdown {
      storedBreakdown(fuid: $fuid, domain:$domain, keyLevel1: "ASSET_GROUP", keyLevel2: "ASSET_DETAIL") {
        fuid
        date
        level1
        keyLevel1
        exposurePart
        breakdownLevel2 {
          fuid
          date
          level1
          keyLevel1
          level2
          keyLevel2
          exposurePart
        }
      }
      storedBreakdownHistory(fuid: $fuid, keyLevel1: "ASSET_GROUP") {
        fuid
        date
        level1
        keyLevel1
        level2
        keyLevel2
        level3
        keyLevel3
        weight_fund
        weight_benchmark
        exposure
        rate_sensitivity
        delta
      }
    }
  }`

  const qVariables = {
    isinShare,
    domain: domainString,
    movementsFilter: movementsFilterName,
    lang: lang || 'fr',
    fuid: isinShare,
  }
  if (!lookthrough) {
    qVariables.domainAllocation = await getLastAllocationDate(isinShare, domainString)
  }
  const res = await getGqlQuery(query, qVariables)
  return res
}

function extractYearAndMonth(input) {
  let date

  if (input.includes('|')) {
    // If it's a range, we only consider the end date of the range
    const parts = input.split('|')
    date = new Date(parts[1])
  } else {
    date = new Date(input)
  }

  if (!isNaN(date.getDate())) {
    const year = date.toISOString().slice(0, 4)
    const monthWithYear = date.toISOString().slice(0, 7)

    return {
      year,
      month: monthWithYear,
    }
  } else {
    throw new Error('Invalid input date format.')
  }
}
export const getAnalyticFromQuery = (fn, period, metric, analytics) => {
  let fund, benchmark
  if (fn === 'volatility') {
    if (period === '1y') {
      ;({ fund, benchmark } = analytics.volatility1y)
    } else if (period === '3y') {
      ;({ fund, benchmark } = analytics.volatility3y)
    }

    return metric === 'fund' ? fund : benchmark
  } else {
    const k = fn + period
    return analytics[k]
  }
}
export const getKeyPerformances = (analytics, domainString, noBenchmark) => {
  const { year, month } = extractYearAndMonth(domainString)
  const yearlyPerformance = analytics.yearlyPerformances.find(e => e.year === year)
  const { fund: fundPerformanceYtd, benchmark: benchmarkPerformanceYtd } = yearlyPerformance
  const monthlyPerformance = analytics.monthlyPerformances.find(e => e.month === month)
  const { fund: fundPerformance1m, benchmark: benchmarkPerformance1m } = monthlyPerformance

  const { fund: fundVolatility1y, benchmark: benchmarkVolatility1y } = analytics.volatility1y
  if (noBenchmark) {
    return { fundPerformance1m, fundPerformanceYtd, fundVolatility1y }
  }
  return {
    fundPerformance1m,
    fundPerformanceYtd,
    fundVolatility1y,
    benchmarkVolatility1y,
    benchmarkPerformance1m,
    benchmarkPerformanceYtd,
  }
}
// Utility function to get the last day of a month
function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0).toISOString().split('T')[0]
}

export const getLastAllocationDate = async (isinShare, dateString) => {
  const q = `
  query lastAllocationDateQuery($isinShare: String!) {
    businessData {
      share(shareId: $isinShare) {
        allocationDates
      }
    }
  }
  `
  const qVariables = {
    isinShare,
  }

  const res = await getGqlQuery(q, qVariables)
  const allocationDates = res.businessData.share.allocationDates.slice().sort((a, b) => (b > a ? 1 : b < a ? -1 : 0))
  if (dateString.length === 7) {
    // Case 1: dateString is "YYYY-MM"
    const monthStart = dateString + '-01'
    const nextMonth = ((parseInt(dateString.split('-')[1], 10) % 12) + 1).toString().padStart(2, '0')
    const year = nextMonth === '01' ? (parseInt(dateString.split('-')[0], 10) + 1).toString() : dateString.split('-')[0]
    const monthEnd = year + '-' + nextMonth + '-01'

    for (const date of allocationDates) {
      if (date >= monthStart && date < monthEnd) {
        return date
      }
    }
  } else if (dateString.includes('|')) {
    // Check if there's a '|' in the dateString
    const [startDate, endDate] = dateString.split('|')

    if (startDate.length === 7 && endDate.length === 7) {
      // Case 4: dateString is "YYYY-MM|YYYY-MM"
      const lastDay = getLastDayOfMonth(parseInt(endDate.split('-')[0], 10), parseInt(endDate.split('-')[1], 10) - 1)

      for (const date of allocationDates) {
        if (date <= lastDay) {
          return date
        }
      }
    } else {
      // Case 3: dateString is "YYYY-MM-DD|YYYY-MM-DD"
      for (const date of allocationDates) {
        if (date <= endDate) {
          return date
        }
      }
    }
  } else {
    // Case 2: dateString is "YYYY-MM-DD"
    for (const date of allocationDates) {
      if (date <= dateString) {
        return date
      }
    }
  }

  return null
}
export const getTranslations = async lang => {
  const q = `query TranslationsQuery($lang: Lang!) {
    businessData{
      translation(lang: $lang)
    }
  }`
  const qVariables = {
    lang,
  }
  const res = await getGqlQuery(q, qVariables)
  return res.businessData.translation
}
export const getGqlQuery = async (
  query,
  queryVariables,
  endpoint = '/dr' /* NOTE: '/dr' points to env.VITE_GRAPHQL_ENDPOINT */,
) => {
  try {
    const res = await axios.post(
      endpoint,
      {
        query,
        variables: queryVariables,
      },
      // NOTE: the Authorization token is automatically injected by an axios interceptor
    )

    if (res.data && res.data.data) {
      return res.data.data
    }
  } catch (error) {
    console.error('Axios request error:', error)
  }
}

export const getGroupedAllocations = (allocations, field, filterFn = d => d, rebase) => {
  if (['duration', 'rating_sb', 'duration_to_maturity'].includes(field)) {
    filterFn = e =>
      e.axis?.asset_type !== 'Futures' && e.axis?.asset_type !== 'Options' && e.axis?.asset_class === 'Obligations'
  }

  const grouped = allocations.filter(filterFn).reduce(function (rv, x) {
    rv[x.axis[field]] = (rv[x.axis[field]] || 0) + x.weight_fund
    return rv
  }, {})
  let sorted = Object.entries(grouped).sort(([keyA, valueA], [keyB, valueB]) => {
    // If either key is 'Cash_Autres', sort accordingly
    if (keyA === 'Cash_Autres') return 1
    if (keyB === 'Cash_Autres') return -1

    return valueB - valueA
  })

  sorted = sorted.reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {})

  if (rebase) {
    const total = Object.values(sorted).reduce((sum, value) => sum + value, 0)
    for (const key in sorted) {
      sorted[key] = sorted[key] / total
    }
  }
  return sorted
}

export const getSectorIgb = input => {
  if (!input) {
    return null
  }
  const parsedInput = JSON.parse(input)
  return parsedInput.reduce((acc, sector) => {
    acc[sector.Code_Repart] = sector.Poids_Repart
    return acc
  }, {})
}

export const metadataSortCapi = (allocations, field) => {
  if (field === 'cap_size_category')
    return d => ['Giant', 'Large', 'Mid', 'Small', 'Cash_Autres', 'NA', 'Unexplained', 'Undefined'].indexOf(d.key)
  if (field === 'cap_size_fixe')
    return d =>
      ['Large Caps', 'Mid Caps', 'Small Caps', 'Micro Caps', 'Cash_Autres', 'NA', 'Unexplained', 'Undefined'].indexOf(
        d.key,
      )
  if (field === 'cap_size_micro') {
    const list_capi = allocations.map(d => d.axis[field]).unique()
    return d =>
      [
        ...list_capi
          .filter(d => d.startsWith('>'))
          .map(d => +d.replace('>', ''))
          .sort()
          .reverse()
          .map(d => '>' + d),
        ...list_capi
          .filter(d => !d.startsWith('>'))
          .sort(d => (d.startsWith('<') ? 1000 + +d : d))
          .reverse(),
      ].indexOf(d.key)
  }
  if (field === 'duration') return d => ['d_1-', 'd_1-3', 'd_3-5', 'd_5-7', 'd_7+', 'NA'].indexOf(d.key)
  if (field === 'rating_sb')
    return d => ['AAA', 'AA', 'A', 'BBB', 'BB', 'B', 'CCC', 'CC', 'C', 'D', 'NR', 'NA'].indexOf(d.key)
  return d => -d.value + (d.key === 'Cash_Autres' ? 1000 : 0)
}

export const getMainLines = lines => {
  if (!lines) {
    return []
  }
  const ret = []
  for (let i = 0; i < lines.length; i++) {
    const l = lines[i]
    ret.push([
      l.name,
      window.format('.1%')(l.weight_fund),
      $root.t[l.dimension0] ? `${$root.t[l.dimension0]} / ${$root.t[l.dimension1]}` : '',
    ])
  }

  return ret
}

export const getMainLinesIsr = lines => {
  const ret = [['', 'weight', $root.t[$root.userflow.esg_note] || $root.userflow.esg_note, '']]
  for (let i = 0; i < lines.length; i++) {
    const l = lines[i]
    ret.push([
      l.name,
      window.format('.1%')(l.weight_fund),
      window.format('.1f')(+l.dimension2 || '-'),
      `${$root.t[l.dimension0]} / ${$root.t[l.dimension1]}`,
    ])
  }

  return ret
}

export const getBestContributorsWeightDiff = (contributors, n, headers) => {
  if (!contributors) {
    return []
  }

  const group1 = contributors
    .filter(e => e.weight_diff || e.weight_diff === 0)
    .slice()
    .sort((a, b) => b.weight_diff - a.weight_diff)
  const bestContributors = headers ? [headers] : []
  for (let i = 0; i < n; i++) {
    const name = group1[i] ? group1[i].name : ''
    const contribution = group1[i] ? group1[i].contribution_fund : ''
    const formattedVal = window.format('.2%')(contribution)
    const weight_diff = group1[i] ? group1[i].weight_diff : ''
    bestContributors.push([name, weight_diff, formattedVal])
  }
  return bestContributors
}

export const getWorstContributorsWeightDiff = (contributors, n, headers) => {
  if (!contributors) {
    return []
  }

  const group1 = contributors
    .filter(e => e.weight_diff || e.weight_diff === 0)
    .slice()
    .sort((a, b) => a.weight_diff - b.weight_diff)

  const worstContributors = headers ? [headers] : []
  for (let i = 0; i < n; i++) {
    const name = group1[i] ? group1[i].name : ''
    const contribution = group1[i] ? group1[i].contribution_fund : ''
    const formattedVal = window.format('.2%')(contribution)
    const weight_diff = group1[i] ? group1[i].weight_diff : ''
    worstContributors.push([name, weight_diff, formattedVal])
  }
  return worstContributors
}

export const getBestContributors = (contributors, n, contributorsNxPack, headers, addWeightFund) => {
  if (!contributors && !contributorsNxPack) {
    return []
  }

  let group1 = contributors
    .filter(contributor => contributor.contribution > 0)
    .sort((a, b) => b.contribution - a.contribution)

  if (contributorsNxPack !== '') {
    group1 = parseNxPack(contributorsNxPack)
  }

  function parseNxPack(str) {
    const parts = str.split(',')
    const result = []
    for (let i = 0; i < parts.length; i += 2) {
      const name = parts[i].trim()
      const contribution = parts[i + 1].trim()
      result.push({ name, contribution })
    }
    return result
  }

  const bestContributors = headers ? [headers] : []
  for (let i = 0; i < n; i++) {
    const name = group1[i] ? group1[i].name : ''
    const contribution = group1[i] ? group1[i].contribution : ''
    const formattedVal = contributorsNxPack !== '' ? contribution : window.format('.2%')(contribution)

    let weight_fund = ''
    if (addWeightFund && group1[i]) {
      weight_fund = group1[i].weight_fund || window.format('.2%')(0)
    }
    bestContributors.push([name, formattedVal, weight_fund])
  }
  return bestContributors
}

export const getWorstContributors = (contributors, n, detractorsNxpack, headers, addWeightFund) => {
  if (!contributors && !detractorsNxpack) {
    return []
  }

  let group2 = contributors
    .filter(contributor => contributor.contribution < 0)
    .sort((a, b) => a.contribution - b.contribution)

  function parseNxPack(str) {
    const parts = str.split(',')
    const result = []
    for (let i = 0; i < parts.length; i += 2) {
      const name = parts[i].trim()
      const contribution = parts[i + 1].trim()
      result.push({ name, contribution })
    }
    return result
  }

  if (detractorsNxpack !== '') {
    group2 = parseNxPack(detractorsNxpack)
  }

  const worstContributors = headers ? [headers] : []
  for (let i = 0; i < n; i++) {
    const name = group2[i] ? group2[i].name : ''
    const contribution = group2[i] ? group2[i].contribution : ''
    const formattedVal = detractorsNxpack !== '' ? contribution : window.format('.2%')(contribution)
    let weight_fund = ''
    if (addWeightFund && group2[i]) {
      weight_fund = group2[i].weight_fund || window.format('.2%')(0)
    }
    worstContributors.push([name, formattedVal, weight_fund])
  }
  return worstContributors
}
export const getMainContributors = (contributors, n, contributorsNxPack = '', detractorsNxpack = '') => {
  if (!contributors && !contributorsNxPack && !detractorsNxpack) {
    return []
  }

  function parseNxPack(str) {
    const parts = str.split(',')
    const result = []
    for (let i = 0; i < parts.length; i += 2) {
      const name = parts[i].trim()
      const contribution = parts[i + 1].trim()
      result.push({ name, contribution })
    }
    return result
  }

  let group1 = contributors
    .filter(contributor => contributor.contribution)
    .sort((a, b) => b.contribution - a.contribution)
  let group2 = contributors
    .filter(contributor => contributor.contribution < 0)
    .sort((a, b) => a.contribution - b.contribution)

  if (contributorsNxPack !== '') {
    group1 = parseNxPack(contributorsNxPack)
  }
  if (detractorsNxpack !== '') {
    group2 = parseNxPack(detractorsNxpack)
  }

  const finalArray = []
  for (let i = 0; i < n; i++) {
    const name1 = group1[i] ? group1[i].name : '-'
    const val1 = group1[i] ? group1[i].contribution : ''
    const name2 = group2[i] ? group2[i].name : '-'
    const val2 = group2[i] ? group2[i].contribution : ''

    const formattedVal1 = contributorsNxPack !== '' ? val1 : val1 !== '' ? window.format('+.1f')(val1 * 100) : val1
    const formattedVal2 = detractorsNxpack !== '' ? val2 : val2 !== '' ? window.format('+.1f')(val2 * 100) : val2

    finalArray.push([name1, formattedVal1, name2, formattedVal2])
  }
  return finalArray
}

export const getMainMovements = (transactions, n, purchasesNxPack = '', salesNxPack = '') => {
  if (!transactions && purchasesNxPack === '' && salesNxPack === '') {
    return []
  }

  const parseNxPack = (str, type) => {
    const parts = str.split(',')
    const result = []
    for (let i = 0; i < parts.length; i++) {
      const name = parts[i].trim()
      result.push({ name, type })
    }
    return result
  }

  let groupPurchase = transactions
    ? transactions
        .filter(transaction => transaction.type === 'purchase' || transaction.type === 'reinforcement')
        .sort((a, b) => b.var - a.var)
    : []

  let groupSale = transactions
    ? transactions
        .filter(transaction => transaction.type === 'cutback' || transaction.type === 'sale')
        .sort((a, b) => a.var - b.var)
    : []

  if (purchasesNxPack !== '') {
    groupPurchase = parseNxPack(purchasesNxPack, 'purchase')
  }
  if (salesNxPack !== '') {
    groupSale = parseNxPack(salesNxPack, 'sale')
  }

  const finalArray = []
  const isPurchase = t => {
    return t.type === 'purchase' && purchasesNxPack === '' ? '(+) ' : ''
  }
  const isSale = t => {
    return t.type === 'sale' && salesNxPack === '' ? '(-) ' : ''
  }

  for (let i = 0; i < n; i++) {
    const val1 = groupPurchase[i] ? isPurchase(groupPurchase[i]) + groupPurchase[i].name : ''
    const val2 = groupSale[i] ? isSale(groupSale[i]) + groupSale[i].name : ''
    finalArray.push([val1, val2])
  }

  return finalArray
}

export const getNxPackTable = (nxPackTables, name = null, noBenchmarkArg) => {
  if (!name) {
    return null
  }
  const noBenchmark = !$root.userflow?.benchmark || noBenchmarkArg
  let fullName = name
  if (
    noBenchmark &&
    ($root.db.table[name + '_no_benchmark'] || nxPackTables.includes(e => e.name === fullName + '_no_benchmark'))
  )
    fullName += '_no_benchmark'

  for (let i = 0; i < nxPackTables.length; i++) {
    if (nxPackTables[i].name === fullName) {
      return nxPackTables[i].table
    }
  }
  const dbTableNames = Object.keys($root.db.table)
  for (let i = 0; i < dbTableNames.length; i++) {
    const dbTableName = dbTableNames[i]
    if (dbTableName === fullName) {
      return $root.db.table[dbTableName]
    }
  }
  return null
}
export const slugify = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/ /g, '_')
    .toLowerCase()
}

// problem with allocations ?
export const getExposureHistorical = allocations => {
  const ret = allocations
    .filter(v => v.date <= $root.domain[1])
    .group('date')
    .map(d => ({ exposure: d.sum('exposure_fund') }))
  return ret
}

export const getManagers = managersString => {
  if (!managersString) {
    return []
  }
  const ret = managersString.split(', ').map(name => {
    const slug = slugify(name)
    const nameParts = name.split(' ')
    const firstName = nameParts[0]
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : ''
    return {
      name,
      slug,
      firstName,
      lastName,
      img: `${slug}.jpg`,
    }
  })
  return ret
}

export const getExposureEvolution = (breakdown = [], level1Filter = []) => {
  if (breakdown.length === 0) {
    return null
  }
  const ret = {}
  breakdown.forEach(item => {
    item.values?.forEach(v => {
      if (!level1Filter.length || level1Filter.includes(v.key)) {
        if (!ret[item.date]) {
          ret[item.date] = 0
        }
        ret[item.date] += v.value
      }
    })
  })
  return ret
}

export const getExposureByAssetClass = (breakdown = [], excludeLevel1 = []) => {
  if (breakdown.length === 0) {
    return null
  }
  const ret = {}
  breakdown.forEach(item => {
    if (!excludeLevel1.length || !excludeLevel1.includes(item.name)) {
      ret[item.name] = item.exposure
    }
  })
  return ret
}

export const getTableSurMesure = breakdown => {
  return breakdown.map(item => ({
    header: [item.name, format('.1%')(item.exposure)],
    content: item.children.map(detail => [detail.name, format('.1%')(detail.exposure)]),
  }))
}

export const getYearlyPerformanceLimit = pdfParamLimitNumberOfYear => {
  return (pdfParamLimitNumberOfYear || '6y').slice(0, -1)
}
