<style>
.pdf-table-stacked .line.header {
  font-weight: 600;
  background: var(--background);
}
.pdf-table-stacked .uppercase {
  text-transform: uppercase;
}
</style>

<template lang="pug">
.pdf-table-stacked.pdf-table
  template(v-for="group in data")
    .line(class="header" )
      .cell(v-for="(cell, cnum) in group.header")
        div.uppercase(v-html="display(cell, group.header, cnum)" v-if="cell !== ''")
    .line(v-for="line in group.content")
      .cell(v-for="(cell, cnum) in line")
        div(v-html="display(cell, line, cnum)" v-if="cell !== ''")
</template>

<script>
export const additions = {}
export default {
  props: ['data', 'metadata', 'otherProps'],
  data() {
    const m = this.metadata || {}
    return {
      focus: [],
      format: m.format,
    }
  },
  methods: {
    input(lnum, cnum, value) {
      if (!window.set_debounced) window.set_debounced = set.debounce(2000)
      const key = this.$root.userflow.table.find(this.data)
      set_debounced(['userflows', this.$root.userflow.name, 'table', key, lnum, cnum].join('.'), value)
    },
    display(cell, line, cnum) {
      if (/^[a-zA-Z_]+\.[\w\d]+(\.[\w\d]+)?$/.test(cell)) {
        try {
          return window.format(cell.split('.')[0])(window.new_analytics(cell, this.otherProps))
        } catch (e) {
          return cell
        }
      }
      if (/=>/.test(cell))
        try {
          const value = function () {
            return eval(cell)()
          }.call($root)
          return window.format(this.format)(this.t[value] || value)
        } catch (e) {
          console.log('ERROR', cell, e)
          return ''
        }
      return this.t[cell] || window.format(this.format)(cell)
    },
  },
}
</script>
