<style></style>

<template lang="pug">
.pdf-text {{ text }}
</template>

<script>
export const additions = {}
export default {
  props: ['data'],
  computed: {
    text() {
      return $root.t[this.data] || this.data
    },
  },
}
</script>
