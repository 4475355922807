<style>
.pdf footer {
  flex-direction: column;
  position: relative;
  display: flex;
}
.pdf footer > * {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.pdf footer > :first-child {
  min-width: fit-content;
}
.pdf footer > :nth-child(2) {
  margin: 0;
  text-align: justify;
  line-height: 1;
}
.pdf .disclaimer > * > * {
  font-size: 7px;
}
.pdf footer {
  flex-direction: row;
  margin-top: auto;
}
.pdf footer .pdf-text {
  font-size: 9px;
  text-align: justify;
  white-space: pre-wrap;
}
.pdf .meeschaert footer > :first-child {
  margin: 0;
  max-width: fit-content !important;
  background: none !important;
}
.screen-factsheet .pdf .meeschaert footer > :first-child .pdf-text {
  text-align: justify;
}
</style>

<template lang="pug">
footer
  .div(v-if="!meeschaert")
    img(src="https://platform.100m.io/dist/mandarine/qr_code.png")
    pdf-text(style="white-space: pre;" data="Footer")
  .disclaimer.row
    pdf-text(data="Disclaimer_General_Titre")
    pdf-text(data="Disclaimer_General")
    pdf-text(data="Disclaimer_Risk_Titre")
    pdf-text(data="Disclaimer_Risk")
</template>

<script>
export const additions = {}
export default {
  props: {
    meeschaert: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
