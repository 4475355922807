<template>
  <builder-pie class="builder-pie" v-bind="{ ...props, nxChartOptions }"></builder-pie>
</template>
<script setup lang="ts">
import { BuilderChartProps } from '@100-m/hauru/src/applications/builder/composables/builderComponent'

const props = withDefaults(defineProps<BuilderChartProps>(), {})
const count = (Array.isArray(props.data) && props.data.length) || 10
const paletteFn = (idx: number) => `rgb(var(--secondary), ${(1 - idx / count).toFixed(1)})`
const nxChartOptions = {
  palette: Array.from({ length: 10 }, (_, idx) => paletteFn(idx)),
}
</script>

<script lang="ts">
import { pie as api } from '@100-m/hauru/src/applications/builder/composables/builderOptions'

export default {
  api,
}
</script>
<style scoped>
.builder-pie :deep(.pie-chart) {
  /* max-height: 100px;
  height: 100px; */
  @apply mb-4 h-[100px] min-h-[100px];
}
</style>
