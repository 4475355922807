export default {
  data() {
    const monthly_performances = this.$root.x.performance
      .group(d => d.date.slice(0, 7))
      .map((data, year, i, ds) => {
        const prev_data = ds[ds.keys()[ds.keys().indexOf(year) - 1]]
        if (prev_data) data = [prev_data.last()].concat(data)
        const r = {
          fund: +data.last().fund / +data.first().fund - 1,
          benchmark: +data.last().benchmark / +data.first().benchmark - 1,
        }
        r.diff = r.fund - r.benchmark
        return r
      })

    return {
      tooltip: null,
      guideline: null,
      plot_nav: null,
      plot_aum: null,
      monthly_performances,
    }
  },
  watch: {
    guideline() {
      if (!this.guideline) {
        this.plot_nav && this.plot_nav.xGuideline.value(new Date(this.$root.domain.last()))
        this.plot_aum && this.plot_aum.xGuideline.value(new Date(this.$root.domain.last()))
      }
    },
  },
  computed: {
    static() {
      if (!$root.xf.performance || !$root.xf.allocation) return {}
      const last_periode_date = $root.x.performance.filter(d => d.date === $root.domain[1]).last() || {}
      const alloc = $root.xf.allocation.data.filter(d => d.valuation !== 0 && d.date === $root.domain.last())

      return {
        nav: last_periode_date.fund,
        real_nav: last_periode_date.real_nav || last_periode_date.fund,
        aum: last_periode_date.aum_fund || alloc.sum('valuation'),
        linenum: alloc.filter(d => !['UNEXPLAINED', 'CASH'].includes(d.isin)).length,
      }
    },
    performance_kpi() {
      return [
        [
          'performance',
          this.$root.xf.performance.data.last().fund / this.$root.xf.performance.data.first().fund - 1,
          '+.2%',
        ],
        this.$root.userflow.benchmark === '' || !this.$root.userflow.benchmark
          ? ''
          : [
              'performance_benchmark',
              this.$root.xf.performance.data.last().benchmark / this.$root.xf.performance.data.first().benchmark - 1,
              '+.2%',
            ],
      ]
    },
    navkpi() {
      return this.$root.x.performance.reduce((acc, v) => {
        acc[v.date] = v.fund
        return acc
      }, {})
    },
    aumkpi() {
      return this.$root.x.allocation.group('date').map(d => d.sum('valuation'))
    },
  },
}
