<style scoped>
.hide {
  visibility: hidden;
}
.screen-documents .block {
  padding: 0;
}
.screen-documents .block iframe {
  flex: 1;
}
.screen-documents .nav-top .tabs {
  display: none;
}
.screen-documents .block iframe {
  flex: 1;
}
</style>

<template lang="pug">
.row
  h1 {{ t.documents || documents }}
  a.button.primary.download(@click="printIframeContent") {{ t.save_pdf }}
block.expand
  iframe(:src="link" ref="iframe")
</template>

<script>
import { useDates } from '@100-m/hauru/src/applications/asset-management/composables/useDates.js'

const { getPerformanceDates } = useDates()
export const additions = {
  calendar: 'period',
  icon: 'ic_folder_open',
  datasets: ['performance'],
}
export default {
  data() {
    return {
      params: {
        isinShare: this.$route.fullPath.split('/')[2].split('-')[1],
        domain: $root.domain.join('|') || this.$route.query.domain,
        lang: this.$route.query.lang || $root.lang,
        period: 'monthly',
      },
    }
  },
  computed: {
    link() {
      return `${window.origin}${this.$route.path.replace('documents', 'factsheet_report')}?domain=${$root.domain.join('|')}&lang=${$root.lang}`
    },
  },
  methods: {
    printIframeContent() {
      const iframe = this.$refs.iframe
      if (iframe && iframe.contentWindow) {
        iframe.contentWindow.focus()
        iframe.contentWindow.print()
      }
    },
  },
  async created() {
    $root.dates = await getPerformanceDates(this.params)
  },
}
</script>
