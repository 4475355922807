import { getGqlQuery } from './new_utils'

const getFundIdBenchmarkId = async shareId => {
  const queryVariables = {
    shareId,
  }

  const query = `query getFundId($shareId: String!) {
        businessData {
          share(shareId: $shareId) {
            fundId
            benchmark_id
          }
        }
    }`
  const res = await getGqlQuery(query, queryVariables)
  return {
    fundId: res.businessData.share.fundId,
    benchmarkId: res.businessData.share.benchmark_id,
  }
}

export const getOverviewQuery = async shareId => {
  const query = `
  query OverviewQuery($shareId: String!) {
    businessData {
      share(shareId: $shareId) {
        
        analytics {
          domainHistorical {
            date
            fund
            benchmark
            benchmark2
            benchmark3
          }
          historicalRealNav {
            date
            real_nav
          }
          historicalAum {
            aum
            date
          }
        }
      }
    }
  }`
  const queryVariables = { shareId }
  const res = await getGqlQuery(query, queryVariables)
  return res
}
export const run2 = async shareId => {
  const res = await getOverviewQuery(shareId)

  const getRiskFree = date => {
    return -1
  }
  const getRealNav = date => {
    let ret = res?.businessData?.share?.analytics?.historicalRealNav
    return ret.find(e => e.date.includes(date))?.real_nav || -1
  }
  const analytics = res?.businessData?.share?.analytics
  let ret = analytics.domainHistorical.map(e => ({
    ...e,
    ratio: '1.0',
    fx_rate: '1.0',
    nav_brut: getRealNav(e.date),
    real_nav: getRealNav(e.date),
    nav_benchmark: e.benchmark,
    risk_free: getRiskFree(e.date),
    isin_share: shareId,
  }))

  return ret
}
