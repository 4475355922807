<style>
.pdf header {
  padding-bottom: 16px;
  border-bottom: 6px solid var(--primary);
  font: 600 12px/1 MarkPro;
  text-transform: uppercase;
  position: relative;
  display: flex;
}
.pdf header .column {
  flex: 1;
  margin-right: 20px;
}
.pdf header .company {
  font-size: 18px;
  font-weight: 400;
}
.pdf header .fund {
  padding: 2px 0;
  font-size: 54px;
  font-weight: 500;
}
.pdf header .fund.long {
  font-size: 47px;
}
.pdf header .type {
  margin-right: 20px;
  text-transform: none;
}
.pdf header .type,
.pdf header .isin {
  font-weight: 800;
}
.pdf header img.isr {
  height: 52px;
  margin-left: 15px;
}
.pdf header .period {
  margin: 0 10px 0 auto;
  font-weight: 400;
}
.pdf header .logo {
  max-height: 86px;
}
.pdf-page:nth-child(n + 2) header {
  padding-bottom: 12px;
  border-width: 3px;
}
.pdf-page:nth-child(n + 2) header :matches(.row:last-child, .isr) {
  display: none;
}
.pdf-page:nth-child(n + 2) header .logo {
  max-height: 50px;
}
.meeschaert_logo {
  max-width: 120px;
  object-fit: contain;
  margin-left: auto;
}
</style>

<template lang="pug">
header
  .column
    .company(v-if="$root.userflow.fund_name_part1") {{ $root.userflow.fund_name_part1 }}
    .company(:style="{color: 'white'}")(v-else) {{ '-' }}
    .row
      .fund(:class="{ long: ($root.share.share_name).length > 20 }") {{ $root.share.fund_name_part2 }}
      img.isr(v-if="$root.userflow.label_isr" :src="(t[$root.userflow.label_isr] || 'isr_fr')  + '.png'")
      img.logo.meeschaert_logo(src="/logo-meeschaert.jpg" v-if="meeschaert")
    .row
      .type {{ t[$root.userflow.category] || $root.userflow.category }}
      .isin ISIN {{ this.$route.params.userflow.split('-')[1] }}
      .period {{ $root.period[0] === 'quarterly' ? '' : t[$root.period[0] + '_report'] }}
      .date {{ $root.period[0] === 'quarterly' ? t.from + ' ' + new Date($root.domain[0]).format('day, mon, year', $root.lang) + ' ' + t.to + ' ' + new Date($root.domain[1]).format('day, mon, year', $root.lang) : $root.period[1] }}
  img.logo(src="https://platform.100m.io/dist/mandarine/icon.png" v-if="!meeschaert")
</template>

<script>
import { defineProps } from 'vue'
export const additions = {}
export default {
  props: {
    meeschaert: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
