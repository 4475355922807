export default {
  data() {
    return {
      sortByOther: top => d => top.concat('other').indexOf(d.x),
      plotclikFilterDimension: dimension => (d, e) =>
        d.x === 'other'
          ? update_filter(
              dimension,
              this.$root.xf.attribution[dimension]
                .keys()
                .filter(d => !this.post_data(dimension).top_categories.includes(d))
                .join('|'),
            )
          : update_filter(dimension, d.x),
      kpi_rewrite: d => ({
        title: d[0],
        value: window.format(d[2] || d[0])(d[1]),
      }),
      positive_kpi: d => d[1] > 0,
      negative_kpi: d => d[1] < 0,
    }
  },
  computed: {
    id_isin() {
      if (this.$root.x.mapping.first().keys().includes('id')) return 'id'
      return 'isin'
    },
    userflows() {
      return this.$root.db.userflows.filter(
        d => !this.$root.query.category || this.$root.query.category.split('|').includes(d.category),
      )
    },
    manager() {
      if (!this.$root.db.profiles) return
      return this.$root.db.profiles[this.$root.userflow.managers[(this.$root.query.managers || 1) - 1]]
    },
    dimensions() {
      if (this.$root.query && this.$root.query.axis) {
        return this.$root.userflow.dimensions
          .map(d => (this.$root.userflow.dimensions.find('underlying_' + d) ? 'underlying_' + d : d))
          .unique()
          .chunk(2)
      }
      return (
        (this.$root.userflow.dimensions &&
          this.$root.userflow.dimensions.filter(d => !d.startsWith('underlying')).chunk(2)) ||
        []
      )
    },
    allocation() {
      if (!this.$root.xf || !this.$root.xf.allocation) return
      const alloc = this.$root.xf.allocation.map(dim =>
        dim.map((v, k) => v && v[(this.$root.query.axis || 'weight') + '_' + (this.$root.query.metric || 'fund')]),
      )
      return alloc
      // if (!this.$root.config.screens[this.$route.params.screen].group) return alloc
      // return Object.assign(
      //   {},
      //   alloc,
      //   this.$root.config.screens[this.$route.params.screen].group.keys().reduce((acc, g) => {
      //     this.$root.xf.allocation
      //       .filter((dim_val, dim_key) => dim_key !== 'data')
      //       .map(
      //         (dim_val, dim_key) =>
      //           (acc[g + '_' + dim_key] = dim_val.map(
      //             (v, k) => v && v[g + '_' + (this.$root.query.axis || 'weight') + '_' + (this.$root.query.metric || 'fund')]
      //           ))
      //       )
      //     return acc
      //   }, {})
      // )
    },
    contribution() {
      if (!this.$root.xf || !this.$root.xf.contribution) return
      return this.$root.xf.contribution.map(dim =>
        dim.map((v, k) => v && v['contribution_' + (this.$root.query.metric || 'fund')]),
      )
    },
    metadata() {
      if (!this.$root.xf) return
      const hack_key = this.$root.xf.allocation ? 'allocation' : 'contribution'
      return this.$root.xf[hack_key].map((dim, dimension) => {
        const fmt =
          vm.query.axis === 'sensitaux'
            ? 'pt'
            : vm.config.formats[vm.$root.xf.allocation ? 'allocation' : 'contribution'] || '.2%'
        if (dimension === 'rating') {
          return {
            dimension: 'rating',
            group: d => d.x.replace(/[+-]/, '').replace('N.A.', 'NR'),
            sort: d => d.first().x.slice(0, 1) + [3, 2, 1].indexOf(d.first().x.replace(/-|\+/, '').length),
            format: fmt,
          }
        }
        if (dimension === 'inst_capi_class') {
          return {
            dimension: 'inst_capi_class',
            sort: d =>
              ['Large Cap', 'Mid Cap', 'Small Cap', 'Micro Cap', 'NA', 'Unexplained', 'Undefined'].indexOf(d.first().x),
          }
        }
        if (dimension === 'cap_size_category') {
          return {
            dimension: 'cap_size_category',
            sort: d =>
              ['Giant', 'Large', 'Mid', 'Small', 'Cash_Autres', 'NA', 'Unexplained', 'Undefined'].indexOf(d.first().x),
          }
        }
        if (dimension === 'cap_size_micro') {
          return {
            dimension: 'cap_size_micro',
            sort: d => ['>400', '>300', '>200', '>100', '<100', 'Cash_Autres', ''].indexOf(d.first().x),
          }
        }
        if (dimension === 'years_to_maturity') {
          return {
            dimension: 'years_to_maturity',
            group: d => {
              if (d.x < 1) return '0-1'
              if (d.x < 3) return '1-3'
              if (d.x < 5) return '3-5'
              if (d.x < 7) return '5-7'
              if (d.x < 10) return '7-10'
              if (d.x < 15) return '10-15'
              return 'na'
            },
            sort: d => d.first().x.slice(0, 1),
            format: fmt,
          }
        }
        return { dimension, max: 6, format: fmt }
      })
    },
  },
  methods: {
    tableclick() {
      return this.contributors
        .filter(this.searchFN)
        .map(d =>
          d.filter((v, k) =>
            ['name', 'weight', 'contribution', 'price_change', 'pnl', 'quantity', 'price'].includes(k),
          ),
        )
        .dlCSV(this.$root.lang, 'lines.csv')
    },
  },
  mounted() {
    if (vm.screen.path !== 'allocation') {
      update_query({ axis: '' })
    }
  },
}
