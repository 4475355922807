<style scoped>
.block-performance_yearly .legend .benchmark {
  display: none;
}
</style>

<script>
export const additions = { theme: '.pdf-page' }
// ["info", "performance", "allocation", "management", "contribution", "details", "look_through"]
import mixin1 from '../../mixins/global.js'
import mixin2 from '../../mixins/info.js'
import mixins from '../../mixins/old-mixins.js'
import mixin9 from '../../mixins/factsheet.js'
import {
  getFactsheetQuery,
  getGroupedAllocations,
  getMainLines,
  getMainContributors,
  getMainMovements,
  getNxPackTable,
  getManagers,
  getExposureHistorical,
  getYearlyPerformanceLimit,
} from '../../new_utils'

export default {
  mixins: [mixin1, mixin2, mixins, mixin9],
  async mounted() {
    $root.refresh = $root.refresh + 1
    const isinShare = this.$root.$route.fullPath.split('/')[2].split('-')[1]
    const domainString = this.$root.$route.query.domain || $root.domain.join('|')
    const fundId = $root.userflow.fundId
    const d = await getFactsheetQuery(fundId, isinShare, domainString)
    this.mainLines = getMainLines(d.businessData.share.linesTop10)
    this.comment = d.businessData.share.comment?.value ? d.businessData.share.comment?.value : ''
    this.managers = getManagers(d.businessData.share.characteristics.managers)
    this.orientation = d.businessData.share.characteristics.orientation
    this.riskIndicatorsTable = getNxPackTable(d.businessData.nxPackTables, 'risk_indicators', true)
    this.cumulatedPerformanceTable = getNxPackTable(d.businessData.nxPackTables, 'cumulated_performance', true)
    this.shareCurrency = d.businessData.share.share_currency
    this.netAssetValue = d.businessData.share.analytics.netAssetValue
    this.srri = d.businessData.share.characteristics.srri
    this.mainMovements = getMainMovements(d.businessData.share.transactions.all, 3)
    this.mainContributors = getMainContributors(d.businessData.share.mainContributors, 3)
    this.benchmark_disclaimer = d.businessData.share.characteristics.benchmark_disclaimer
    this.getGroupedAllocations = field => {
      return getGroupedAllocations(d.businessData.share.allocation, field)
    }
    this.exposureHistorical = getExposureHistorical(d.businessData.share.allocation)
    this.yearlyPerformancesLimit = getYearlyPerformanceLimit(
      d.referential.share.otherFundCharacteristics?.pdfparamlimitnumberofyear,
    )
    this.loaded = true

    // debugger
  },
  data() {
    return {
      managers: '',
      comment: '',
      orientation: '',
      riskIndicatorsTable: '',
      cumulatedPerformanceTable: '',
      shareCurrency: '',
      netAssetValue: null,
      srri: '',
      mainMovements: [],
      mainContributors: [],
      benchmark_disclaimer: '',
      mainLines: [],
      allocationsSector: {},
      allocationsCountry: {},
      allocationsCapSize: {},
      allocationsCurrency: {},
      loaded: false,
      exposureHistorical,
      yearlyPerformancesLimit: null,
    }
  },
}
</script>
<template lang="pug">
.pdf-page(v-if="loaded").grid
  pdf-header
  .row.expand
    .column
      .stars.risk-scale
        .label {{ t.risk_scale }}
        .star(:class="{ active: i === +$root.userflow.shares[$route.params.userflow.split('-')[1]].srri }" v-for="i in 7") {{ i }}
      .stars.morningstar(v-if="$root.share.ms_stars")
        .label {{ t.morningstar }}
        .star(v-for="i in +$root.share.ms_stars")
          <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z" fill="#FEDB3D" fill-rule="evenodd"/></svg>
      .stars.fww(v-if="$root.share.fww")
        .label {{ t.FWW || 'FWW' }}
        .star(v-for="i in +$root.share.fww")
          <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg"><path d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z" fill="#FEDB3D" fill-rule="evenodd"/></svg>
      brick(type="pdf-key-performances" v-if="has_enough_perf_data")
      .managers
        .profile(v-for="manager in managers")
          img(:src="manager.img")
          div {{ manager.firstName }}
          div.surname {{ manager.lastName}}
      brick.comment(:data="comment" type="pdf-text")
      brick.main_table(title="main_movements_percent" :data="mainMovements")
      brick.morningstar_disclaimer(data="main_movements_legend")
      brick.main_table(title="main_contributors_percent" :data="mainContributors")
    .column(:class="{ 'space-between': has_enough_perf_data }")
      div
        brick.orientation(:data="orientation")
        h2.performance_and_risk {{ t.performance_and_risk }}
        brick.italic(data="Disclaimer_Perf")  
        brick.italic(:data="benchmark_disclaimer" type="pdf-text")
      brick(title="performance_inception" :class="{ 'not-enough-data': !has_enough_perf_data }" type="line" data="computed.performance_historical_asof_2" :metadata="{ format: plotLineDecimal }" @rebind="$event.yAxis._computeWidth = () => 30")
        .nav
          .value(v-html="unit(window.format('nav')(static.real_nav)+ $root.share.share_currency)")
          .name {{ t.nav }}
      brick(data="nxpack.Disclaimer_12mois" v-if="!has_enough_perf_data")
      brick(title="performance_yearly" type="vbar" data="computed.yearly_rolling_performances_reflex_de" :metadata="{ limit: yearlyPerformancesLimit, format: '.1%' }" v-if="has_enough_perf_data")
      div
        brick(title="performance_cumulated_annualized" :data="cumulatedPerformanceTable" v-if="has_enough_perf_data")
        brick.morningstar_disclaimer(:data="$root.share.mstar_category" v-if="has_enough_perf_data")
      brick(title="risk_indicators" :data="riskIndicatorsTable" v-if="has_enough_perf_data" type="pdf-table")
.pdf-page(v-if="loaded").grid
  pdf-header
  h2.portfolio {{ t.portfolio }}
  .row
    brick.main_lines.half(title="main_lines" type="pdf-table" :data="mainLines") 
    brick(:title="$root.userflow.dimensions[0]" type="hbar" :data="allocationsSector" :metadata="{ format: '.1%', limit: 15, other_key: metadata_other($root.userflow.dimensions[0]), sort: d => -d.value + (d.key === 'Cash_Autres' ? 1000 : 0) }")
    brick(:title="$root.userflow.dimensions[1]" type="hbar" :data="allocationsCountry" :metadata="{ format: '.1%', limit: 15, other_key: metadata_other($root.userflow.dimensions[1]), sort: d => -d.value + (d.key === 'Cash_Autres' ? 1000 : 0) }")
  h2.profile {{ t.profile }}
  .row.profile_raw
    .half
      brick.profile_indicators(:otherProps="{filterIndicators:true}" :title="'profile_indicators,at,' + $root.period[1]" :data="$root.userflow.benchmark ? 'nxpack.indicators' : 'nxpack.indicators_no_benchmark'")
      brick(title="profile_esg" type="pdf-profile-esg" v-if="$root.userflow.ratio_esg1_fonds || $root.userflow.ratio_esg2_fonds")
    //- brick(title="ptf_expo_h" type="line" data="computed.exposure_historical" :metadata="{ format: '.2%' }" @rebind="$event.yAxis._computeWidth = () => 30")
  .separator
  h2.characteristics {{ t.characteristics }}
  .row
    //- brick.characteristics-table(data="nxpack.characteristics_de")
  pdf-footer

</template>
