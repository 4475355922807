<template>
  <h2 class="text-xl font-bold uppercase leading-tight" :class="options.icon">
    {{ options.title && context.t[options.title.replace(/^translation\./, '')] }}
  </h2>
</template>
<script setup lang="ts">
const { data, options, context } = defineProps(['data', 'options', 'context'])
</script>
<script lang="ts">
export default {
  api: {
    icon: {
      label: 'Icon',
      autocomplete: () => ['performance', 'portfolio', 'profile', 'characteristics'],
    },
  },
}
</script>
<style scoped>
h2::before {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
  vertical-align: middle;
}
h2.portfolio::before {
  content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><g fill="%2344AF65" fill-rule="evenodd"><path d="M8.084 9.442V1.811c-4.248.073-7.67 3.46-7.67 7.631S3.837 17 8.085 17.073c.046.001.093.004.139.004 4.313 0 7.808-3.418 7.808-7.635H8.084zm-6.628.644a6.534 6.534 0 0 1-.032-.644c0-.335.032-.662.082-.984l.233-.986a6.55 6.55 0 0 1 .785-1.626l.799-.986a6.853 6.853 0 0 1 3.753-1.969V9.624l-.583.462-1.242.986-2.643 2.098a6.575 6.575 0 0 1-.975-2.098l-.177-.986zm6.767 6.005l-.048-.001-.073-.002a6.854 6.854 0 0 1-4.865-2.147l4.426-3.513h7.285c-.488 3.2-3.318 5.663-6.725 5.663z"/><path d="M10.088.12c-.047 0-.093.004-.14.004v7.632h7.948C17.896 3.539 14.4.12 10.088.12zm.87 1.04c3.008.378 5.41 2.686 5.855 5.61h-5.856V1.16z"/></g></svg>');
}

h2.profile::before {
  content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M19.273 9.44h-1.57a7.588 7.588 0 0 0-7.111-7.105V.761a.47.47 0 0 0-.94 0v1.575A7.589 7.589 0 0 0 2.558 9.44H.97a.47.47 0 0 0 0 .94h1.586a7.589 7.589 0 0 0 7.096 7.103v1.578a.469.469 0 0 0 .939 0v-1.577a7.59 7.59 0 0 0 7.112-7.105h1.569a.47.47 0 1 0 0-.939zm-9.62 7.101a6.658 6.658 0 0 1-6.155-6.162h1.877a4.78 4.78 0 0 0 4.278 4.285v1.877zm0-11.386A4.779 4.779 0 0 0 5.375 9.44H3.498a6.658 6.658 0 0 1 6.155-6.162v1.877zm.939 11.387v-1.877a4.778 4.778 0 0 0 4.294-4.286h1.876a6.658 6.658 0 0 1-6.17 6.163zm4.294-7.102a4.778 4.778 0 0 0-4.294-4.286V3.276a6.659 6.659 0 0 1 6.17 6.164h-1.876z" fill="%2344AF65"/><path d="M10.13 13.75a3.845 3.845 0 0 1-3.84-3.84 3.844 3.844 0 0 1 3.84-3.84 3.844 3.844 0 0 1 3.84 3.84 3.845 3.845 0 0 1-3.84 3.84" fill="%23FFFFFE"/><path d="M10.13 7.72a2.189 2.189 0 1 0 0 4.378 2.189 2.189 0 0 0 0-4.378m0 3.438A1.25 1.25 0 0 1 8.88 9.91c0-.689.56-1.25 1.25-1.25.689 0 1.25.561 1.25 1.25a1.25 1.25 0 0 1-1.25 1.25" fill="%2344AF65"/></g></svg>');
}

h2.performance::before {
  content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path d="M0 0h22v22H0z"/><path d="M6.36 14.652a.557.557 0 0 0 .771-.175l2.48-3.932 1.755 2.516a.563.563 0 0 0 .847.083l2.535-2.443 1.859 1.754a.559.559 0 0 0 .903-.199L20.493 4.8a.558.558 0 1 0-1.038-.416l-2.678 6.694-1.65-1.556a.56.56 0 0 0-.772.004l-2.446 2.358-1.862-2.67a.54.54 0 0 0-.472-.239.56.56 0 0 0-.46.261l-2.93 4.645a.56.56 0 0 0 .175.771" fill="%2344AF65"/><path fill="%2344AF65" d="M21.008 16.677H4.458V3.361H3.34v2.126H1.647v1.12H3.34v3.386H1.647v1.118H3.34v3.387H1.647v1.12H3.34v2.177h2.275v1.631h1.119v-1.63h2.89v1.63h1.119v-1.63h2.89v1.63h1.119v-1.63h2.89v1.63h1.118v-1.63h2.248z"/></g></svg>');
}

h2.characteristics::before {
  content: url('data:image/svg+xml;utf8,<svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg"><g fill="%2344AF65"><path d="M17.579 2.12h-8.85l-.132.021v.008a.658.658 0 0 0-.233.121L4.17 5.705a.622.622 0 0 0-.221.39H3.94V18.82c0 .866.727 1.575 1.617 1.575h12.02c.89 0 1.618-.709 1.618-1.575V3.695c0-.866-.728-1.575-1.617-1.575zm.485 16.701a.485.485 0 0 1-.485.472H5.558a.485.485 0 0 1-.485-.472V6.612l3.55-2.908v3.067H6.917v1.103h2.836l.002-4.652h7.824c.263 0 .485.217.485.473v15.126z"/><path d="M15.962 17.297h-5.09a.56.56 0 0 1-.565-.551c0-.305.254-.551.566-.551h5.089c.312 0 .566.245.566.55a.56.56 0 0 1-.566.551M15.962 14.237h-5.09a.56.56 0 0 1-.565-.551.56.56 0 0 1 .566-.551h5.089a.56.56 0 0 1 .566.55.56.56 0 0 1-.566.552M15.962 11.177h-5.09a.56.56 0 0 1-.565-.55.56.56 0 0 1 .566-.552h5.089a.56.56 0 0 1 .566.551.56.56 0 0 1-.566.551M7.838 11.848L6.53 10.825l.506-.614.665.52 1.078-1.36.64.481zM7.838 14.74L6.53 13.72l.506-.616.665.52 1.078-1.36.64.481zM7.838 17.631l-1.308-1.02.506-.615.665.519 1.078-1.359.64.482z"/></g></svg>');
}
</style>
