import { getLastAllocationDate } from './new_utils'

const getRawShare = async (isinShare, domainString, lookthrough) => {
  try {
    const positionCountPart = lookthrough
      ? `positionCount: positionCountLookthrough(domain: $domain)`
      : `positionCount(domain: $domain)`
    const allocationQueryPart = lookthrough
      ? `allocation: lookthrough(domain: $domain)`
      : `allocation(domain: $domainAllocation)`
    const query = `query Characteristics(
        $shareId: String!
        $domain: Domain!
        $fuid: FuidInput!
        ${lookthrough ? '' : `$domainAllocation: Domain!`}) {
        referential {
          share(fuid: $fuid) {
            otherFundCharacteristics
          }
        }
      businessData {
        share(shareId: $shareId) {
          analytics {
            aum(domain: $domain) {
              aum_fund_in_fund_ccy
              aum_share_in_fund_ccy
              date
              shareId
            }
          }
          characteristics {
            secteurs_igb
            fww
            srri
            achats
            ratio1
            ratio2
            ventes
            cut_off
            horizon
            category
            esg_note
            managers
            ms_stars
            mstar_1m
            mstar_1y
            mstar_3m
            mstar_3y
            mstar_5y
            wkn_code
            custodian
            fund_type
            label_isr
            mstar_ytd
            fund_admin
            mstar_crea
            ratio_esg1
            ratio_esg2
            settlement
            share_name
            capi_repart
            date_nxpack
            detracteurs
            orientation
            ratio1_data
            ratio2_data
            capi_moyenne
            code_mg_fund
            expo_actions
            pea_eligible
            share_letter
            code_mg_share
            contributeurs
            bloomberg_code
            initial_charge
            management_fee
            mstar_category
            redemption_fee
            fund_name_part1
            fund_name_part2
            peapme_eligible
            performance_fee
            axis_of_analysis
            ratio_esg1_bench
            ratio_esg1_fonds
            ratio_esg2_bench
            ratio_esg2_fonds
            amf_classification
            management_company
            valuation_frequency
            benchmark_disclaimer
            dimensions_main_lines
            expo_action_lookthrough
            minimum_initial_subscription
            assignment_distributable_sums
            mean_rating
            mean_maturity
            rate_sensitivity
            yield_to_maturity
            effective_yield
          }
          fundId
          shareId
          fund_name
          fund_currency
          share_currency
          benchmark_id
          dedicated_fund
          slug
          axis_of_analysis
          fund_inception
          share_inception
          ${positionCountPart}
          libEmetteurCount(domain: $domain)
          ${allocationQueryPart}{
            weight_active_share_benchmark
          }
        }
      }
    
    }`
    const res = await axios.post(
      '/dr',
      {
        query,
        variables: {
          fuid: { fuid: isinShare },
          shareId: isinShare,
          domain: domainString,
          domainAllocation: await getLastAllocationDate(isinShare, domainString),
        },
      },
      {
        headers: {
          Authorization:
            'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5ESkJRelU1UlVNeE9VTTBSRUpHTlVNek5EQTVSVEE1T0VVNE9FVkVOell4TkVFME5VVTNNQSJ9.eyJodHRwczovLzEwMG0uaW8vYXBwX21ldGFkYXRhIjp7InJvbGUiOiJhZG1pbiJ9LCJodHRwczovLzEwMG0uaW8vdXNlcl9tZXRhZGF0YSI6e30sIm5pY2tuYW1lIjoibGF1cmVudC5nYXV0aGllciIsIm5hbWUiOiJsYXVyZW50LmdhdXRoaWVyQG5lb3hhbS5jb20iLCJwaWN0dXJlIjoiaHR0cHM6Ly9zLmdyYXZhdGFyLmNvbS9hdmF0YXIvYmM3YTkzMjkxNzVhZTkzYmIwNzc4MzEwMjMwNWJjNjc_cz00ODAmcj1wZyZkPWh0dHBzJTNBJTJGJTJGY2RuLmF1dGgwLmNvbSUyRmF2YXRhcnMlMkZsYS5wbmciLCJ1cGRhdGVkX2F0IjoiMjAyMy0wOC0xNlQwOTo1MTowMi4xNTZaIiwiZW1haWwiOiJsYXVyZW50LmdhdXRoaWVyQG5lb3hhbS5jb20iLCJlbWFpbF92ZXJpZmllZCI6ZmFsc2UsImlzcyI6Imh0dHBzOi8vbWFuZGFyaW5lLmV1LmF1dGgwLmNvbS8iLCJhdWQiOiJ6STg2UWU1dG83dXZkNFNtOG52dWJCMlJDNjVldzRmRCIsImlhdCI6MTY5MjE3OTQ2MiwiZXhwIjoxNjkzOTA3NDYyLCJzdWIiOiJhdXRoMHw1Yzk4OWY1ZTE5NjI2NDJlYjNmMTJiODQifQ.DCtN398ANwzzkssGZyGxnhrV--aA6VTdXpKz3fuO5XxXsfOwZIKylXZ8LsivZtlHCRNN9-oVhSoWCdlFSeZWRznKwfHI0PfOlN9gTH1FJdgqa_RQa82nbAYPTQRVvqypFo6TQBq_4aETUlO9eqfcS6dSDDtXHKiALbQP4BFNG6xGXpQICg_a1W4tmlle6zAYN20jGTzatnP-3s0l75TB2GZ8ANp4jgvQtNpLtpOL6MVviFx-Y5yipWUi6R2uqPsgqCgdT2IviOMTqJkOXNdKeNFzT4nokJpZSAa7BUPYJm0GbBfQc3JpftQyE0m3NRIjOflBmtf3FdPOYRSQ0IdJKQ',
        },
      },
    )

    if (res.data && res.data.data) {
      return res.data.data
    } else {
      console.error('Error fetching data', res.data.errors)
    }
  } catch (error) {
    console.error('Axios request error:', error)
  }
}

const userflows = async (isinShare, domainString, lookthrough) => {
  const res = await getRawShare(isinShare, domainString, lookthrough)
  const rawShare = res.businessData.share
  const obj = {}
  const fundShare = {}
  fundShare[rawShare.shareId] = {
    ...rawShare,
    ...rawShare.characteristics,
    ...rawShare.analytics,
    active_share:
      +res.referential.share.otherFundCharacteristics.active_share ||
      rawShare.allocation.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.weight_active_share_benchmark
      }, 0),
    initial_charge:
      typeof rawShare.characteristics.initial_charge === 'string'
        ? parseFloat(rawShare.characteristics.initial_charge.replace(',', '.'))
        : rawShare.characteristics.initial_charge,
    management_fee:
      typeof rawShare.characteristics.management_fee === 'string'
        ? parseFloat(rawShare.characteristics.management_fee.replace(',', '.'))
        : rawShare.characteristics.management_fee,
    redemption_fee:
      typeof rawShare.characteristics.redemption_fee === 'string'
        ? parseFloat(rawShare.characteristics.redemption_fee.replace(',', '.'))
        : rawShare.characteristics.redemption_fee,
    share_inception: rawShare.share_inception,
    fund_inception: rawShare.fund_inception,
  }
  obj[rawShare.slug] = {
    ...rawShare,
    ...rawShare.characteristics,
    dimensions: rawShare.axis_of_analysis,
    ...rawShare.analytics,
    shares: { ...fundShare },
    benchmark: rawShare.benchmark_id,
    dedicated: fundShare.dedicated_fund,
  }
  delete obj[rawShare.slug].characteristics

  return obj
}
export default userflows
