<template lang="pug">
template(v-if="$root.screen.theme && shouldRenderFactsheet")
  .screen-factsheet(v-if="$root.db && $root.x")
    .pdf.page.factsheet
      router-view
template(v-else)
  nav-top
  section(:class="$root.screen.theme ? 'pdf' : 'page'")
    router-view
  nav-bottom
  toast(:messages="$root.messages")
</template>

<script>
import { setTranslation } from '@100-m/hauru/src/features/translation.js'
import { getTranslations, getAnalyticFromQuery } from './new_utils'
import legacyTranslation from './t.js' // consider to remove this by asking to add in nxpack translation
import { format as d3format } from 'd3-format'
/// global methods
import './raw.js'
import './raw-addon.js'

import t from './t.js'

// static new data with good api
import { run2 } from './buildX.js'

import db from './db.js'
import userflow from './userflow.js'

export default {
  data() {
    return {
      shouldRenderFactsheet: false, // default value
    }
  },
  async created() {
    // only run for factsheets
    if (!$root.screen.theme) return

    const lookthrough = !!this.$root.$route.fullPath.split('/')[3].includes('lookthrough')
    const isinShare = this.$root.$route.fullPath.split('/')[2].split('-')[1]
    const domainString = $root.$route.query.domain || $root.domain.join('|')

    const performance = await run2(isinShare)
    db.userflows = await userflow(isinShare, domainString, lookthrough)
    $root.db = db

    $root.x = {
      performance,
      mapping: [],
      transactions: [],
      allocation: [
        {
          date: '2020-12-16',
          date_cotation: '',
          exposure: '',
          isin: 'UNEXPLAINED',
          pnl_benchmark: 0,
          pnl_fund: 0,
          pnl_share_benchmark: 0,
          pnl_share_fund: -2.9921145,
          price: 0,
          quantity: 0,
          valuation: 0,
          weight_benchmark: 0,
          weight_fund: 0,
          sector_gics_1: 'Cash_Autres',
          country: 'Cash_Autres',
          cap_size_category: 'Cash_Autres',
          currency: 'EUR',
          sector_gics_2: 'Cash_Autres',
          label2: 'Cash_Autres',
          flag_ignore_contribution: true,
          perf_brute_out: false,
        },
      ],
    }
    // debugger
    $root.dates = performance.map(e => e.date)

    $root.config = {
      analytics: {
        performance_log: 'no-log',
        tracking_error: 'no-log',
        volatility_day: 5,
        volatility_log: 'no-log',
      },
      formats: {
        allocation: '.2%',
        allocation_effect: '+.2%',
        attribution: '+bp',
        aum_DKK: '.1fs',
        aum_EUR: '.1fs€',
        contribution: '+bp',
        drawdown: '.2%',
        excess_return: '+.2%',
        information_ratio: '.2f',
        nav: '.2f',
        performance: '+.2%',
        pnl: '.3s€',
        position: '.3s',
        price: '.3s€',
        price_change: '+.2%',
        quantity: '.3s',
        selection_effect: '+.2%',
        sensitaux: 'pt',
        sharpe_ratio: '.2f',
        sum: '+.2%',
        tracking_error: '.2%',
        volatility: '.2%',
        weight: '.2%',
      },
    }
    $root.screen.formats = {
      '1m': '.1%',
      '1y': '.1%',
      '3y': '.1%',
      allocation: '.1%',
      aum: '.2s€',
      drawdown: '.1%',
      information_ratio: '.1f',
      nav: '.2f',
      performance: '+.1%',
      sharpe_ratio: '.1f',
      tracking_error: '.1%',
      volatility: '.1%',
      weight: '.1%',
      ytd: '.1%',
    }
    $root.x.map(v => (v.dates = Object.fromEntries(v.map((v, i) => [v.date, i]))))
    const xf = {}
    xf.performance = {
      data: [
        {
          date: '2023-03-31',
          isin_share: 'LU2257981683',
          fund: 1046.4992467263398,
          benchmark: 294.50377480667055,
          real_nav: 1046.4992467263398,
          shares: '4032.0008',
          risk_free: 111.22399999999999,
          ratio: '0.9999999999999984',
          fx_rate: '1.0',
          nav_benchmark: '294.503774806671',
          nav_brut: '0.0',
        },
      ],
    }
    xf.transactions = { data: [] }
    xf.contribution = { data: [] }
    xf.allocation = { data: [] }
    $root.xf = xf
    window.vm = $root
    Date.plus = (date, str) => date.modify(str, '+')

    window.mapping = Object.fromEntries($root.x.mapping.map(v => [v.isin, v]))

    const translationsConfigFallback = this.$root.config.translation[$root.lang.split('_')[0]]
    const legacyTranslationFallback = legacyTranslation[$root.lang.split('_')[0]]
    const translationsDB = await getTranslations($root.lang)

    const factsheetTranslation = {
      ...$root.lang,
      ...t[$root.lang],
      ...translationsConfigFallback,
      ...legacyTranslationFallback,
      ...translationsDB,
    }

    setTranslation({ [$root.lang]: { ...factsheetTranslation } }, $root.app)

    window.top_bottom = (ds, len) => {
      const positive = ds.filter(d => d[1] >= 0).sort('-1')
      const negative = ds.filter(d => d[1] < 0).sort('1')
      return Array(len)
        .fill()
        .map((d, i) =>
          [positive[i] || ['', ''], negative[i] || ['', '']]
            .filter(d => d)
            .map(d => [d[0], window.format('+.1f')(d[1] * 100)])
            .flatten(),
        )
    }
    window.format = fmt => x => {
      const formats = Object.assign({}, vm.config.formats || {}, vm.screen.formats || {}) //if (['fr', 'es', 'it'].includes(vm.$root.lang)) d3.formatDefaultLocale({ decimal: ',', thousands: ' ', grouping: [3] })
      //else d3.formatDefaultLocale({ decimal: '.', thousands: ',', grouping: [3] })

      if (!fmt) return x

      if (typeof x === 'string') {
        if (new Date(x) == 'Invalid Date' || x.includes('%')) return x
        if (!formats[fmt]) return x
        return new Date(x).format(formats[fmt])
      }

      if (typeof fmt === 'function') return fmt(x)
      if (typeof fmt !== 'string') return
      if (x === 0) fmt = fmt.replace(/\.[0-9]*/, '.0')
      if (x === null || x === undefined) return '' // if (x === 0) return x

      if (fmt === 'M€') return Math.round((x * 100) / 1000000) / 100
      if (fmt === 'bp') return Math.round(x * 10000) + 'bp'
      if (fmt === '+bp') return x > 0 ? '+' + Math.round(x * 10000) + 'bp' : Math.round(x * 10000) + 'bp'
      if (fmt === 'd')
        return (
          parseInt(x / (24 * 60 * 60000)) +
          'd' +
          parseInt((x / 60000 - parseInt(x / (24 * 60 * 60000)) * 24 * 60) / 60) +
          'h'
        )
      if (fmt === 'pt') return d3format.format('.3')(x * 100) + ' '
      if (formats[fmt]) return format(formats[fmt])(x)
      const matchfmt = formats.find((v, f) => RegExp(f).test(fmt))
      if (matchfmt) return format(formats[matchfmt])(x)

      try {
        let [match, f, currency = ''] = fmt
          .replace('USD', '$')
          .replace('EUR', '€')
          .replace('YEN', '¥')
          .replace('GBP', '£')
          .match(/([^$€£¥]*)([$€£¥])?/)

        if (f.slice(-3) === 'CHF') {
          f = f.replace('CHF', '')
          currency = 'CHF'
        }

        if (/s$/.test(f) && Math.abs(x) <= 1) f = f.replace('s', 'f')

        if (f.slice(-2) == 'fs') {
          let precision = parseInt(f[1])
          let scaleFactor = Math.pow(10, precision)

          // Check if the value is >= 1 billion
          if (x >= 1e9) {
            let rounded = Math.round((x / 1e9) * scaleFactor) / scaleFactor // rounding to desired precision in billions
            return rounded + 'B' + currency
          } // Check if the value is >= 1 million
          else if (x >= 1e6) {
            let rounded = Math.round((x / 1e6) * scaleFactor) / scaleFactor // rounding to desired precision in millions
            return rounded + 'M' + currency
          }
          // Check if the value is >= 1 thousand
          else if (x >= 1e3) {
            let rounded = Math.round((x / 1e3) * scaleFactor) / scaleFactor // rounding to desired precision in thousands
            return rounded + 'k' + currency
          }
          // For values less than a thousand
          else {
            let rounded = Math.round(x * scaleFactor) / scaleFactor
            return rounded + currency
          }
        }

        return (
          d3format(f)(x)
            .replace(/^NaNk$/, 0)
            .replace('NaN', 0)
            .replace('G', vm.$root.lang === 'fr' ? 'Md' : 'Bn') + currency
        )
      } catch (e) {
        return x
      }
    }
    ;(window.new_analytics = (input, analyticsInput) => {
      const [fn, period, metric, arg1, arg2] = input.split('.')
      if (!analyticsInput) {
        return window.analytics(input)
      }

      const domain = (period => {
        const first_date = new Date($root.dates.first())
        const last_date = new Date($root.domain[1])
        if (['1m', '2m', '3m', '4m', '5m', '6m', '7m', '8m', '9m', '10m', '11m', '12m'].includes(period))
          return [last_date.minus(`${period.slice(0, -1)} month`).end('month'), last_date]
        if (
          [
            '1y',
            '2y',
            '3y',
            '4y',
            '5y',
            '6y',
            '7y',
            '8y',
            '9y',
            '10y',
            '11y',
            '12y',
            '13y',
            '14y',
            '15y',
            '16y',
            '17y',
            '18y',
            '19y',
            '20y',
          ].includes(period)
        )
          return [last_date.minus(`${period.slice(0, -1)} year`).end('month'), last_date]
        if (period === 'mtd') return [last_date.minus('1 month').end('month'), last_date]
        if (period === 'ytd') return [last_date.minus('1 year').end('year'), last_date]
        if (period === 'inception') return [first_date, last_date]
        if (period.startsWith('years-'))
          return [
            new Date((last_date.getFullYear() - period.split('-')[1]).toString()).minus('year').end('year'),
            new Date((last_date.getFullYear() - period.split('-')[1] + 1).toString()).minus('year').end('year'),
          ]
        if (period === 'domain') return [new Date($root.domain[0]), last_date]
      })(period)

      const dates = $root.x.performance.dates
      if (new Date(domain[0]) < new Date(dates.keys().first())) return '-'

      if (['volatility', 'tracking_error', 'information_ratio', 'sharpe_ratio'].includes(fn)) {
        return getAnalyticFromQuery(fn, period, metric, analyticsInput)
      }
      return window.analytics(input)
    }),
      ($root.refresh = $root.refresh + 1)
    this.shouldRenderFactsheet = true
  },
}
</script>
