<style scoped>
.man-header {
  padding-bottom: 16px;
  border-bottom: 6px solid var(--tertiary);
}
.man-header * {
  font-family: Merlo_Regular;
}
</style>

<template>
  <header class="man-header border-b-4">
    <div class="flex w-full flex-col">
      <div class="text-lg font-normal" v-if="headerData.fund_name_part1">
        {{ headerData.fund_name_part1 }}
      </div>
      <div class="text-white" v-else>{{ '-' }}</div>
      <div class="flex flex-row items-center justify-between text-2xl">
        <div>{{ headerData.fund_name_part2 }}</div>
        <img
          class="ml-4 h-12"
          v-if="headerData.label_isr"
          :src="'https://platform.100m.io/dist/mandarine/' + t(headerData.label_isr) + '.png'"
        />
      </div>
      <div class="flex flex-row items-center gap-2 font-light">
        <div>{{ t(headerData.category) }}</div>
        <div class="flex-1">ISIN {{ headerData.isinShare }}</div>
        <div>
          {{ headerData.period === 'quarterly' ? '' : t(headerData.period + '_report') }}
        </div>
        <div>
          {{ headerData.domain }}
        </div>
      </div>
      <!-- <img class="logo" src="https://platform.100m.io/dist/mandarine/icon.png" /> -->
    </div>
  </header>
  <!-- .column
    .company(v-if="$root.userflow.fund_name_part1") {{ $root.userflow.fund_name_part1 }}
    .company(:style="{color: 'white'}")(v-else) {{ '-' }}
    .row
      .fund(:class="{ long: ($root.share.share_name).length > 20 }") {{ $root.share.fund_name_part2 }}
      img.isr(v-if="$root.userflow.label_isr" :src="'https://platform.100m.io/dist/mandarine/' +  (t[$root.userflow.label_isr] || $root.userflow.label_isr) + '.png'")
    .row
      .type {{ t[$root.userflow.category] || $root.userflow.category }}
      .isin ISIN {{ this.$route.params.userflow.split('-')[1] }}
      .period {{ $root.period[0] === 'quarterly' ? '' : t[$root.period[0] + '_report'] }}
      .date {{ $root.period[0] === 'quarterly' ? t.from + ' ' + new Date($root.domain[0]).format('day, mon, year', $root.lang) + ' ' + t.to + ' ' + new Date($root.domain[1]).format('day, mon, year', $root.lang) : $root.period[1] }}
  img.logo(src="https://platform.100m.io/dist/mandarine/icon.png") -->
</template>

<script setup lang="ts">
import { computed } from 'vue'
const { data, options, context } = defineProps(['data', 'options', 'context'])
// const data = options.context
// debugger
function t(key: string) {
  return context.translations[key] || key
}
const headerData = computed(() => {
  try {
    const shareInfo = context.data.referential?.fundCharacteristics
    return {
      fundName: context.variables.fund.titleize(),
      period: context.variables.period.toLowerCase(),
      domain: context.variables.domain,
      isinShare: context.variables.shareId,
      fund_name_part1: shareInfo?.fund_name_part1,
      fund_name_part2: shareInfo?.fund_name_part2,
      label_isr: shareInfo?.label_isr,
      category: shareInfo?.category,
      lang: context.variables.lang,
    }
  } catch (e) {
    return {
      error: e.message || e,
    }
  }
})
</script>
