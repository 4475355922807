<style>
.pdf .block-profile_esg .content {
  margin-top: 25px;
}
.pdf .block-profile_esg .pdf-kpi {
  display: flex;
  flex-direction: row;
  max-width: 100%;
}
.pdf .block-profile_esg .pdf-kpi .fund {
  color: black;
  background: var(--primary) !important;
  min-width: 100px;
}
.pdf .block-profile_esg .pdf-kpi .fund .value {
  padding: 0;
  font-size: 20px;
  font-weight: 700;
}
.pdf .block-profile_esg .pdf-kpi .benchmark {
  padding: 10px;
  width: 60px;
  color: black;
}
.pdf .block-profile_esg .kpis .column {
  margin: 0px;
}
.pdf .block-profile_esg .kpis .row {
  flex-wrap: unset;
}
.pdf .block-profile_esg .morningstar_disclaimer {
  display: flex;
}
</style>

<template lang="pug">
.kpis
  .column
    .row
      .pdf-kpi(v-if="$root.userflow.ratio_esg1_fonds")
        .fund
          .value(v-html="$root.userflow.ratio_esg1_fonds")
          .label {{ t[$root.userflow.ratio_esg1] || $root.userflow.esg_note }}
        .benchmark
          .label {{ t.benchmark }}
          .value(v-html="$root.userflow.ratio_esg1_bench")
      .pdf-kpi(v-if="$root.userflow.ratio_esg2_fonds")
        .fund
          .value(v-html="$root.userflow.ratio_esg2_fonds")
          .label {{ t.carbon_print }}
        .benchmark
          .label {{ t.benchmark }}
          .value(v-html="$root.userflow.ratio_esg2_bench")
    .morningstar_disclaimer {{ t['empreinte_carbone_definition'] || 'empreinte_carbone_definition' }}
</template>

<script>
export const additions = {}
export default {}
</script>
