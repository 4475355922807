export default {
  config: {
    menu: ['reports', 'documents', 'templates', 'workflows', 'data-reports'],
    detail_info: [
      'format',
      'id',
      'steps',
      'template',
      'fund_name',
      'isin',
      'code_mg_share',
      'domain',
      'last_update_date',
      'language',
      'jurisdiction',
      'period',
    ],
    report_info: [
      'format',
      'template',
      'workflow_name',
      'schedule',
      'fund_name',
      'isin',
      'code_mg_share',
      'share_letter',
      'language',
      'jurisdiction',
      'patterns',
      'step',
      'gamme_fonds',
      'type_gestion',
    ],
    extra_share_characteristics: ['code_mg_share', 'type_gestion', 'gamme_fonds'],
    stamping_pattern: {
      mandarine_IT: `(runContext) => {
        runContext = runContext.context
        function getLastBusinessDayOfMonthFormatted(date) {
            const dateObj = new Date(date)
            const lastDayOfMonth = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0) 
            const day = lastDayOfMonth.getDay()
            const lastBusinessDay = day === 0 ? lastDayOfMonth.getDate() - 2 : day === 6 ? lastDayOfMonth.getDate() - 1 : lastDayOfMonth.getDate()
            const month = ('0' + (dateObj.getMonth() + 1)).slice(-2) 
            return \`\${dateObj.getFullYear()}\${month}\${lastBusinessDay}\`
        }
        let year, month, date
        let d0 = runContext.domain.split('|')[0]
        if (d0 === new Date(d0.split('-')[0]).toISOString().slice(0, 7) && runContext.domain.includes('|')) {
            date = [runContext.domain.split('|')[1], 'YTD'].join('-')
        }
        if (runContext.domain.length === 4) {
            year = runContext.domain
            month = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(5, 7)
            date = runContext.domain + '-' + month + '-YTD'
        }
        if (/[0-9]{4}-Q[1,2,3,4]/.test(runContext.domain)) {
            year = runContext.domain.slice(0, 4) 
            month = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(5, 7) 
            date = runContext.domain.slice(0, 4) + '-' + month + '-Quarter'
        }
        if (runContext.domain.includes('|') && typeof date === 'undefined') {
            let d1 = runContext.domain.split('|')[1]
            year = d1.slice(0, 4)
            month = d1.slice(5, 7)
            date = d1.slice(0, 4) + '-' + d1.slice(5, 7) + '-Quarter'
        }
        const domain_date = runContext.domain.includes('|') ? runContext.domain.split('|')[1] : runContext.domain
        if (!date) {
            year = new Date(domain_date).getFullYear() 
            month = new Date(domain_date).getMonth() + 1 
            date = domain_date.slice(0, 7)
        }
        const fund_share = (runContext.share_name || '').replace(/ /g, '') || (runContext.fund_name.replace(/ /g, '') + (runContext.share || ''))
        if (runContext.template === 'report') {
            const filename_attrib = ['Attribution', fund_share, date].join('_')
            const fullpath_attrib = ['Attribution', year, month, filename_attrib].join('/') + '.' + runContext.format 
            return fullpath_attrib
        }
        const lang = runContext.language.slice(0, 2).toLowerCase()
        const jurisdiction = runContext.language.length > 2 ? runContext.language.slice(3).toUpperCase() : lang.toUpperCase()
        const filename1 = ['Reporting', fund_share, date].join('_')
        const filename2 = ['SHARE_MR', runContext.isin, lang, jurisdiction,
            getLastBusinessDayOfMonthFormatted(date),fund_share].join('_')
        const type_gestion = runContext.type_gestion 
        const gamme_fonds = runContext.gamme_fonds 
        const fullpath2 = ['Reporting', runContext.language.toUpperCase(), year, month, type_gestion, gamme_fonds, filename2].join('/') + (runContext.template === "factsheet_report" ? "_report" : "") + '.' + runContext.format 
        return fullpath2
    }`,
      mandarine_professional: `(runContext) => {
        runContext = runContext.context
        function getLastBusinessDayOfMonthFormatted(date) {
            const dateObj = new Date(date)
            const lastDayOfMonth = new Date(dateObj.getFullYear(), dateObj.getMonth() + 1, 0)
            const day = lastDayOfMonth.getDay()
            const lastBusinessDay = day === 0 ? lastDayOfMonth.getDate() - 2 : day === 6 ? lastDayOfMonth.getDate() - 1 : lastDayOfMonth.getDate()
            const month = ('0' + (dateObj.getMonth() + 1)).slice(-2)
            return \`\${dateObj.getFullYear()}\${month}\${lastBusinessDay}\`
        }
        let year, month, date
        let d0 = runContext.domain.split('|')[0]
        if (d0 === new Date(d0.split('-')[0]).toISOString().slice(0, 7) && runContext.domain.includes('|')) {
            date = [runContext.domain.split('|')[1], 'YTD'].join('-')
        } if (runContext.domain.length === 4) {
            year = runContext.domain
            month = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(5, 7)
            date = runContext.domain + '-' + month + '-YTD'
        } if (/[0-9]{4}-Q[1,2,3,4]/.test(runContext.domain)) {
            year = runContext.domain.slice(0, 4)
            month = new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(5, 7)
            date = runContext.domain.slice(0, 4) + '-' + month + '-Quarter'
        } if (runContext.domain.includes('|') && typeof date === 'undefined') {
            let d1 = runContext.domain.split('|')[1]
            year = d1.slice(0, 4)
            month = d1.slice(5, 7)
            date = d1.slice(0, 4) + '-' + d1.slice(5, 7) + '-Quarter'
        } const domain_date = runContext.domain.includes('|') ? runContext.domain.split('|')[1] : runContext.domain
        if (!date) {
            year = new Date(domain_date).getFullYear()
            month = new Date(domain_date).getMonth() + 1
            date = domain_date.slice(0, 7)
        }
        const fund_share = (runContext.share_name || '').replace(/ /g, '') || (runContext.fund_name.replace(/ /g, '') + (runContext.share || ''))
        if (runContext.template === 'factsheet_report') {
            const filename_attrib = ['Attribution', fund_share, date].join('_')
            const fullpath_attrib = ['Attribution', year, month, filename_attrib].join('/') + '.' + runContext.format
            return fullpath_attrib
        } 
        const lang = runContext.language.slice(0, 2).toLowerCase()
        const jurisdiction = runContext.language.length > 2 ? runContext.language.slice(3).toUpperCase() : lang.toUpperCase()
        const filename1 = ['Reporting', fund_share, date].join('_')
        const filename2 = ['SHARE_MR', runContext.isin, lang, jurisdiction, getLastBusinessDayOfMonthFormatted(date)].join('_')
        const type_gestion = runContext.type_gestion 
        const gamme_fonds = runContext.gamme_fonds 
        const fullpath1 = ['Reporting', runContext.language.toUpperCase(), year, month, type_gestion, gamme_fonds, filename1].join('/') + '.' + runContext.format
        return fullpath1
    }`,
    },
  },
}
