<style></style>

<template lang="pug">
header
  .column
    .row.fit_content
      .column.no_margin
        .company {{ $root.userflow.fund_name_part1 || $root.userflow.name }}
        .fund(:class="{ long: ($root.share.share_name).length > 20 }") {{ $root.share.fund_name_part2 }}
      img.isr(:src="(t[$root.userflow.label_isr] || 'isr_fr')  + '.png'")
    .row
      .type {{ t[$root.userflow.category] || $root.userflow.category }}
      .isin ISIN {{ this.$route.params.userflow.split('-')[1] }}
      .period {{ t[$root.period[0] + '_report'] }}
      .date {{ $root.period[1] }}
  img.logo(:src="$root.cssvar.logo.slice(4, -1)")
</template>

<script>
export const additions = {}
export default {}
</script>
