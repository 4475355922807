<template lang="pug">
.kpis
  .pdf-kpi(v-for="k in ['performance.1m', 'performance.ytd', 'volatility.1y']")
      .fund(:class="k.replace('.', '_')")
        .value(v-html="unit(window.format(k.includes('volatility') ? '.1%' : '+.1%')(getValueForKpi(k, 'fund')))")
        .label {{ k.split('.').map(d => t[d]).join('\n') }}
      .benchmark.italic(:class="k.replace('.', '_')" v-if="$root.userflow.benchmark && getValueForKpi(k, 'benchmark')")
        .label {{ t.benchmark }}
        .value(v-html="unit(window.format(k.includes('volatility') ? '.1%' : '+.1%')(getValueForKpi(k, 'benchmark')))")
</template>

<script>
export default {
  props: {
    otherProps: Object,
  },
  methods: {
    getValueForKpi(k, type) {
      if (type === 'fund') {
        if (k === 'performance.1m') return this.otherProps.fundPerformance1m
        if (k === 'performance.ytd') return this.otherProps.fundPerformanceYtd
        if (k === 'volatility.1y') return this.otherProps.fundVolatility1y
      } else if (type === 'benchmark') {
        if (k === 'performance.1m') return this.otherProps.benchmarkPerformance1m
        if (k === 'performance.ytd') return this.otherProps.benchmarkPerformanceYtd
        if (k === 'volatility.1y') return this.otherProps.benchmarkVolatility1y
      }
    },
  },
}
</script>
