<style></style>

<template lang="pug">
img.pdf-img(:src="data")
</template>

<script>
export const additions = {}
export default {
  props: ['data'],
}
</script>
