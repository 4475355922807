<script>
export const additions = { theme: '.pdf-page' }
// ["info", "performance", "allocation", "management", "contribution", "details", "look_through"]
import mixin1 from '../../mixins/global.js'
import mixin2 from '../../mixins/info.js'
import mixins from '../../mixins/old-mixins.js'
import mixin9 from '../../mixins/factsheet.js'
import mixin_dr from '../../mixins/global-on-mounted.js'

export default {
  mixins: [mixin1, mixin2, mixins, mixin9, mixin_dr],
  data() {
    return {
      loaded: false,
    }
  },
}
</script>

<template lang="pug">
.pdf-page(v-if="loaded").grid
  pdf-header
  .row.expand
    .column
      .stars.risk-scale
        .label {{ t.risk_scale }}
        .star(:class="{ active: i+'' === srri }" v-for="i in 7") {{ i }}
      .stars.morningstar(v-if="$root.share.ms_stars")
        .label {{ t.morningstar }}
        .star(v-for="i in +$root.share.ms_stars")
          <svg viewBox="0 0 13 13" xmlns="http://www.w3.org/2000/svg"><path d="M6.97.843l1.466 4.418 4.655.028-3.75 2.76 1.412 4.435L6.97 9.771l-3.782 2.713 1.41-4.436L.852 5.29l4.654-.028z" fill="#FEDB3D" fill-rule="evenodd"/></svg>
      brick(type="pdf-key-performances" v-if="has_enough_perf_data" :otherProps="keyPerformances"
     )
      .managers
        .profile(v-for="manager in managers")
          img(:src="manager.img")
          div {{ manager.firstName }}
          div.surname {{ manager.lastName}}
      brick.comment(:data="comment" type="pdf-text")
      brick.main_table.main_table_wide(title="main_contributors_only_percent" :data="mainContributorsOnly")
      brick.main_table.main_table_wide(title="main_detracteurs_only_percent" :data="mainDetracteursOnly")
    .column(:class="{ 'space-between': has_enough_perf_data }")
      div
        brick.orientation(:data="orientation")
        h2.performance_and_risk {{ t.performance_and_risk }}
        brick.italic(data="Disclaimer_Perf")
        brick.italic(v-if="$root.userflow?.benchmark" :data="benchmark_disclaimer" type="pdf-text")
      brick(title="performance_inception" :class="{ 'not-enough-data': !has_enough_perf_data }" type="line" data="computed.performance_historical_asof" :metadata="{ format: plotLineDecimal }" @rebind="$event.yAxis._computeWidth = () => 30")
        .nav
          .value(v-html="unit(window.format('nav')(static.real_nav)+ shareCurrency)")
          .name {{ t.nav }}
      brick(data="nxpack.Disclaimer_12mois" v-if="!has_enough_perf_data")
      brick(title="performance_yearly" type="vbar" data="computed.performance_yearly_asof"  :metadata="{ limit: yearlyPerformancesLimit, format: '.1%', variant: 'clustered' }" v-if="has_enough_perf_data")
      div
        brick(title="performance_cumulated_annualized" :data="cumulatedPerformanceTable" v-if="has_enough_perf_data" )
        brick.morningstar_disclaimer(:data="$root.share.mstar_category" v-if="has_enough_perf_data")
      brick(title="risk_indicators" :data="riskIndicatorsTable" type="pdf-table" v-if="has_enough_perf_data" :otherProps="analyticsInput")
.pdf-page(v-if="loaded").grid
  pdf-header
  h2.portfolio {{ t.portfolio }}
  .row
    .column
      brick(type="pdf-table-stacked" :data="tableSurMesure")
    .column(v-if="exposureByAssetClass")
      brick.exposure(v-if="exposureByAssetClass" title="exposition_par_classe_actif" type="vbar" :class="{ 'meeschaert_breakdown': meeschaert_theme }" :data="exposureByAssetClass" :metadata="{ variant: 'positive', sort: d => ['AllocN1-Actions','AllocN1-Obligations', 'AllocN1-ObligationsConvertibles', 'AllocN1-Diversifies','AllocN1-Commodities','AllocN1-Monetaire_Liquidites','AllocN1-Change_Fx'].indexOf(d.key)}")
      brick.exposure(v-if="exposureEvolution" title="evolution_expo_nette_actions" type="line" :data="exposureEvolution" :metadata="{ format: '.0%', variant: 'area', yAxisScale: 'SCALE_0_1'}")
  .separator
  h2.characteristics {{ t.characteristics }}
  .row
    brick.characteristics-table(data="nxpack.characteristics")
  pdf-footer
</template>
