<style>
.pdf-look-through-detail {
  margin-top: 50px;
}
.pdf-look-through-detail > .row > .column:first-child {
  min-width: 30%;
  max-width: 30%;
  padding-right: 16px;
  border-color: var(--primary);
  border-right: var(--border);
}
.pdf-look-through-detail > .row > .column:nth-child(2) {
  min-width: 60%;
  max-width: 65%;
  margin: 4px 8px 8px 8px;
}
.pdf-look-through-detail > .row > .column:nth-child(2) > * {
  min-width: 60%;
  margin: 4px 8px 8px 8px;
}
.pdf-look-through-detail > .row > .column:first-child h2 {
  font-size: 14px;
}
.pdf-look-through-detail img {
  margin-top: 20px;
  max-width: 100px;
  max-height: 30px;
}
</style>

<template lang="pug">
.pdf-look-through-detail
  .row.expand
    .column
      img.logo(:src="management_company_img")
      h2 {{ t.key_figures }}
      .stars
        .label {{ t.aum }}
        .value(v-html="window.format('aum')(data.aum)")
      .stars
        .label {{ t.nav }}
        .value(v-html="window.format('.2f' + (data.fund.shares[data.share].share_currency || '€'))(data.nav)")
      .stars.risk-scale
        .label {{ t.risk_scale }}
        .star(:class="{ active: i === +data.fund.risk_scale }" v-for="i in 7") {{ i }}
      .kpis
        .pdf-kpi(v-for="v, k in data.kpis")
          .fund
            .value(v-html="unit(window.format('+.1%')(v.fund))")
            .label {{ k.split('_').map(d => t[d]).join('\n') }}
      h2 {{ t.management_objective || management_objective }}
      .text {{ t[data.fund.management_objective] || data.fund.management_objective }}
    .column
      h2 {{ t[data.fund.fund_name] || data.fund.fund_name }}
      .text {{ t[data.fund.orientation] || data.fund.orientation  }}
      block(title="comment")
      .text {{ comment }}
      block(title="performance_cumulated" type="pdf-table" :data="data.rolling_perf" :metadata="{ format: '.2%' }")
</template>

<script>
export const additions = {}
export default {
  props: ['data'],
  computed: {
    management_company_img() {
      return '/dist/' + this.$root.project + '/look-through/' + this.data.fund.management_company_img
    },
    comment() {
      if (this.data.fund.comment[this.$root.domain[1].slice(0, 7)]) {
        return this.data.fund.comment[this.$root.domain[1].slice(0, 7)][this.$root.lang]
      } else {
        return ''
      }
    },
  },
}
</script>
