<style></style>

<template lang="pug">
.pdf-bar
  div(v-for="bar in bars")
    .name {{ t[bar.name] || bar.name }}
    .value {{ window.format('.1%')(bar.value) }}
    .hbar(:style="{ width: bar.width }")
</template>

<script>
export const additions = {}
export default {
  props: ['data'],
  computed: {
    bars() {
      const min = this.data
        .v()
        .filter(d => d.key !== 'other')
        .map(d => d.value)
        .min()
      const max = this.data
        .v()
        .filter(d => d.key !== 'other')
        .map(d => d.value)
        .max()
      const diff = Math.sign(min) === -1 ? max - min : max
      return this.data.map((v, k) => ({
        name: v.name,
        value: v.value,
        width: v.key === 'other' ? '' : Math.round(Math.abs((28 * v.value) / diff)) + 'px',
        offset: v.key === 'other' ? '' : Math.round(Math.abs((28 * (Math.min(0, v.value) - min)) / diff)) + 'px',
      }))
    },
  },
}
</script>
